const helpList = [
  {
    type: '系统实施培训',
    items: [
      {
        title: '开放平台使用流程培训',
        link: 'https://l.jd.com/student/project/project.du?project_id=658673183',
        clstag: 'Management_1702468662190|4'
      },
      {
        title: '快递快运系统实施专家认证',
        link: '',
        disabled: true
      },
      {
        title: '供应链系统实施专家认证',
        link: '',
        disabled: true
      },
    ]
  },
  {
    type: '我要反馈',
    items: [
      {
        title: '我要建议',
        clstag: 'Management_1702468662190|5',
        link: 'https://get.jd.com/#/survey/index?id=6953843047407083&erp=type'
      },
      // {
      //   title: '我要投诉',
      //   clstag: 'Management_1702468662190|6',
      //   link: '',
      //   disabled: true
      // }
    ]
  },
  {
    type: '系统对接支持',
    items: [
      {
        title: '提报工单',
        clstag: 'Management_1702468662190|7',
        link: 'https://joyspace.jd.com/pages/IVvDkhLYxUJMoIZBCelI'
      }
    ]
  }
]

const knowledgeList = [
  {
    type: '平台规范',
    items: [
      {
        title: '客户对接流程',
        link: 'https://joyspace.jd.com/pages/uBJF1O0sLydhAtmt0web',
        clstag: 'Management_1702468662190|8',
      },
      {
        title: '接口发布流程',
        link: 'https://joyspace.jd.com/pages/5ZsLLkhts0qio8xTsV7e',
        clstag: 'management_1702468662190|9',
        disabled: false
      },
      {
        title: '内容管理规范',
        link: 'https://joyspace.jd.com/pages/u9NtxuGRIqbWGtvXBmKw',
        clstag: 'management_1702468662190|10',
        disabled: false
      },
      {
        title: 'IT运维规范',
        link: 'https://joyspace.jd.com/pages/zdV2JJOF77AwqodF8oZV',
        clstag: 'management_1702468662190|11',
        disabled: false
      },
      {
        title: '需求提报流程',
        link: 'https://joyspace.jd.com/pages/NJmsHbYTmNBf6HW60vYK',
        clstag: 'management_1702468662190|12',
        disabled: false
      },
    ]
  },
  {
    type: '日常工作',
    items: [
      {
        title: '客户技术体验优化',
        link: '',
        clstag: 'Management_1702468662190|4',
        disabled: true
      },
      {
        title: '平台对外接口治理',
        link: 'https://joyspace.jd.com/pages/HXzg4aEmFPq8TN1uVeRG',
        clstag: 'Management_1702468662190|13',
        disabled: false
      },
      {
        title: '内网系统安全监管',
        link: '',
        clstag: '',
        disabled: true
      },
      {
        title: '客户系统稳定保障',
        link: '',
        clstag: '',
        disabled: true
      }
    ]
  },
  {
    type: '运营报告',
    items: [],
    // items: [
    //   {
    //     title: '2023年11月月报',
    //     link: '',
    //     clstag: '',
    //     disabled: true
    //   },
    //   {
    //     title: '2023年10月月报',
    //     link: '',
    //     clstag: '',
    //     disabled: true
    //   },
    //   {
    //     title: '2023年9月月报',
    //     link: '',
    //     clstag: '',
    //     disabled: true
    //   },
    //   {
    //     title: '2023年8月月报',
    //     link: '',
    //     clstag: '',
    //     disabled: true
    //   },
    //   {
    //     title: '2023年7月月报',
    //     link: '',
    //     clstag: '',
    //     disabled: true
    //   },
    // ]
  },
  {
    type: '最佳实践',
    items: [
      {
        title: '京东物流-德邦网络融合',
        link: 'https://joyspace.jd.com/pages/7QxlwrS5QhQtYX7eTOQ9',
        clstag: 'management_1702468662190|14',
      },
      {
        title: '得物快递下单接口改造 ',
        link: 'https://joyspace.jd.com/pages/YlJGpww0hBtTGqWllIrj',
        clstag: 'management_1702468662190|15',
      },
      {
        title: '捷德卡厂对接快递业务',
        link: 'https://joyspace.jd.com/pages/6ZSjCFHz80dNXdSb4VuO',
        // clstag: 'management_1702468662190|8',
        // Look: 待产品补充
      }
    ]
  },
  {
    type: '专家团队',
    items: [
      {
        title: '人员概况 ',
        link: 'https://joyspace.jd.com/pages/DOLnzgigg4YLaXrk7dgK',
        clstag: 'management_1702468662190|16',
      }
    ]
  },
]

export {
  helpList,
  knowledgeList
}
