/**
 * 中国内网：1
 * 中国外网：2
 * 美国内网：3
 * 欧洲内网：4
 * 欧美外网：5
 * */

const loginMap: { [key: string]: { code: number; intranet: boolean } } = {
  /**
   * 测试环境-中国站内网
   * */
  'dev-test-lcp.jdl.com': {
    code: 1,
    intranet: true
  },
  /**
   * 预发环境-中国站内网
   * */
  'uat-admin-open.jdl.com': {
    code: 1,
    intranet: true
  },
  /**
   * 正式环境-中国站内网
   * */
  'admin-open.jdl.com': {
    code: 1,
    intranet: true
  },

  /**
   * 测试环境-中国站外网
   * */
  'dev-test-cloud.jdl.com': {
    code: 2,
    intranet: false
  },
  /**
   * 预发环境-中国站外网
   * */
  'uat-open.jdl.com': {
    code: 2,
    intranet: false
  },
  /**
   * 正式环境-中国站外网
   * */
  'open.jdl.com': {
    code: 2,
    intranet: false
  },

  /**
   * 预发环境-美国站内网
   * */
  'uat-th.lcp.jdl.com': {
    code: 3,
    intranet: true
  },
  /**
   * 正式环境-美国站内网
   * */
  'th.lcp.jdl.com': {
    code: 3,
    intranet: true
  },
  /**
   * 预发环境-欧洲站内网
   * */
  'uat-hl.lcp.jdl.com': {
    code: 4,
    intranet: true
  },
  /**
   * 正式环境-欧洲站内网
   * */
  'hl.lcp.jdl.com': {
    code: 4,
    intranet: true
  },
  /**
   * 预发环境-国际站客户端
   * */
  'uat-open.cloud.jdl.com': {
    code: 5,
    intranet: false
  },
  /**
   * 正式环境-国际站客户端
   * */
  'open.cloud.jdl.com': {
    code: 5,
    intranet: false
  },
  'iopen.jdl.com': {
    code: 5,
    intranet: false
  },
  // 仅做开发使用
  'uat-th1.lcp.jdl.com': {
    code: 3,
    intranet: true
  },
  // 仅做开发使用
  'uat-th2.lcp.jdl.com': {
    code: 3,
    intranet: true
  },
  'uat-hl1.lcp.jdl.com': {
    code: 4,
    intranet: true
  },
  'uat-open1.cloud.jdl.com': {
    code: 5,
    intranet: false
  },
  'uat-open1-cloud.jdl.com': {
    code: 5,
    intranet: false
  }
}

function getEnvLoginConfig() {
  return loginMap[window.location.hostname]
}

export {
  getEnvLoginConfig
}
