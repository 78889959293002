export default {
  needI18n: false,
  extranetHost: 'test-cloud.jdl.com',
  intranetHost: 'test-lcp.jdl.com',
  baseEnv: 'devtest',
  buildEnv: 'publictest',
  baseStreamAccount: '',
  baseStreamCloudAccount: '',
  idaasLogoutDomain: '//test-soms.jdl.com',
  domainErp: '//test.ssa.jd.com',
  gatewayAuthorization: '//test.lop-oauth.jd.com',
  gatewayBaseApi: '//lop-test.jd.com/',
  gatewayFileBaseApi: 'http://lop-test.jdl.com/',
  gatewayOauthBaseApi: 'https://test-proxy.jd.com',
  gatewayUploadLopDn: 'lcp.cloud.sdkfile.com', // test.sdkfile.com
  gatewayPtnr: '{"appid":"test"}',
  gatewayPassportPtnr: '{"appid":"test_passport"}',
  apiQuickPath: '/open-business-document/api-doc/104/110',
  docHow2Order: '/devSupport/151615',
  docHow2OrderInner: '/devSupport/11',
  docSdk: 'devSupport/81',
  softwareLicense: '/devSupport/151',
  sdkUpdateVersion: '//lcp.jdl.com/#/apiNewHand/53036',
  idaasChangePassword: '//test-soms.jdl.com/static/findPwd.html?orgId=1&source=WLY_SAAS&loginType=superAdmin&returnUrl=',
  gatewayApiDecoupling: 'http://lop-test.jdl.com',
  gatewayApiDecouplingLop: 'TEST_LOP_OPEN_APIDEPOT',
  gatewayApiDecouplingOpenBiz: 'https://test-api.jdl.com',
  gatewayApiDecouplingLopOpenBiz: 'TEST_LOP_OPEN_APIDEPOT_OUTER',
  developmentDocumentationId: 53209
}
