<template> 
  <div id="app">
    <router-view />
    <ToTop v-if="isIntranet && computeToTopVisible" />
    <!-- 对外网使用 -->
    <ToTopNew v-else-if="!isIntranet" :isIntranet="false" />
    <!-- <Like v-if="computeIsShow" /> -->
  </div>
</template>

<script>
import ToTop from '@/components/ToTop.vue'
import ToTopFaq from '@/components/ToTopFaq.vue'
import ToTopNew from '@/components/toTop/index.vue'
import Like from '@/components/Like.vue'
import isIntranet from '@/utils/isIntranet.ts'
import { usIntranetLogin } from '@/utils/oauth/login/common/usIntranetLogin'
import { UserModule } from '@/store';

export default {
  name: 'App',
  components: { ToTop, ToTopFaq, ToTopNew, Like },
  data() {
    return {
      isIntranet: isIntranet(),
    }
  },
  computed: {
    computeToTopVisible() {
      const path = `/${this.$route.path.split('/')[1]}`
      const routerTable = ['/chooseSupplier']
      return !routerTable.includes(path)
    },
    computeIsShow() {
      const { name } = this.$route
      const routerTable = [
        'NewHand',
        'ApiNewHand',
        'Qs',
        'DocSoftwareSystem',
        'DevSupport',
        'DocDetail',
        'AccessGuideDetail',
        'ApiDocDetail',
        'MessageDocDetail',
      ]
      return routerTable.includes(name)
    },
  },
  created() {
    // 语言环境设置
    this.$i18n.locale = UserModule.locale;
    // 以下是子午线埋点代码
    const jdpts = {}
    window.jdpts = jdpts
    jdpts._st = new Date().getTime()

    const jaq = []
    window.jaq = jaq || []
    let account = window.env.baseStreamAccount
    if (!isIntranet()) {
      account = window.env.baseStreamCloudAccount
    }
    jaq.push(['domain', 'jdl.com'])
    jaq.push(['account', account])
    jaq.push(['anchorpvflag', 'true'])
    jaq.push(['anchorToUri', 1]);

    (function () {
      const ja = document.createElement('script')
      ja.type = 'text/javascript'
      ja.async = true
      ja.src = '//wl.jd.com/joya.js'
      const s = document.getElementsByTagName('script')[0]
      s.parentNode.insertBefore(ja, s)
    })()
  },
  watch: {
    '$i18n.locale': {
      handler(value) {
        const hashMap = new Map().set('zh-cn', 'zh-CN').set('en-us', 'en-US');
        document
          .querySelector('html')
          .setAttribute('lang', hashMap.get(value) || '');
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
@import "~@/assets/styles/index.scss";
@import "~@/assets/api_sub/main.scss";
html,
body,
#app {
  height: 100%;
}
</style>
