export default {
  needI18n: false,
  extranetHost: 'cloud.jdl.com',
  intranetHost: 'lcp.jdl.com',
  baseEnv: 'prod',
  buildEnv: 'prod',
  baseStreamAccount: 'JA2020_1222750',
  baseStreamCloudAccount: 'JA2020_1233042',
  idaasLogoutDomain: '//account.jdl.com',
  domainErp: '//ssa.jd.com',
  gatewayAuthorization: 'https://uat-oauth.jdl.com',
  gatewayBaseApi: 'https://api.jdl.com/',
  gatewayFileBaseApi: 'https://lop-stream.jdl.com/',
  gatewayOauthBaseApi: 'https://lop-proxy.jd.com',
  gatewayUploadLopDn: 'lcp.cloud.sdkfile.com',
  // gatewayUploadLopDn: "lop.cloud.sdkfile.com",
  gatewayPtnr: '{"appid":"lop_erp"}',
  gatewayPassportPtnr: '{"appid":"lop_passport"}',
  apiQuickPath: '/open-business-document/api-doc/147/176',
  docHow2Order: '/devSupport/53009',
  docHow2OrderInner: '/devSupport/85',
  docSdk: 'devSupport/81',
  softwareLicense: '/devSupport/52751',
  sdkUpdateVersion: 'https://cloud.jdl.com/#/devSupport/53074',
  idaasChangePassword:
    'https://account.jdl.com/static/findPwd.html?orgId=1&source=WLY_SAAS&loginType=superAdmin&returnUrl=',
  gatewayApiDecoupling: 'http://api-lop.jdl.com',
  gatewayApiDecouplingLop: 'PROD_LOP_OPEN_APIDEPOT',
  gatewayApiDecouplingOpenBiz: 'https://api.jdl.com',
  gatewayApiDecouplingLopOpenBiz: 'LOP_OPEN_APIDEPOT_OUTER',
  developmentDocumentationId: 53209,
}
