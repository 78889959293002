<template>
  <div class="tips-area">
    <div class="area-content">
      <div class="tips-title">平台公告</div>
      <div class="tips-content">开放平台管理端改版</div>
      <div class="tips-button" @click="getNoticeDetails()">
        <div class="tips-button-content" :clstag="clstag('Management_1702468662190|17', '')">查看详情</div>
        <div class="tips-button-icon" :clstag="clstag('Management_1702468662190|18', '')"></div>
      </div>
      <div class="tips-close" @click="clsoeNotice()"></div>
    </div>
  </div>
</template>
<script>
import pageClick from '@/utils/pageClick'

export default {
  data() {
    return {

    }
  },
  methods: {
    // 点击事件埋点
    clstag(intranetId, extranetId) {
      return pageClick(intranetId, extranetId)
    },
    getNoticeDetails() {
      window.open('https://joyspace.jd.com/pages/Sy3K7En5gQy5tlok678d', '_blank')
    },
    clsoeNotice() {
      this.$emit('clsoeNotice')
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/mixin.scss";
.tips-area {
  height: 32px;
  color: #EFEFEF;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
  background-color: #3C6EF0;
  .area-content {
    width: 1280px;
    position: relative;
    display: inline-block;
    align-items: center;
    text-wrap: nowrap;
    .tips-title, .tips-content, .tips-button, .tips-close {
      display: inline-block;
    }
    .tips-title {
      height: 20px;
      line-height: 20px;
      color: rgba(60,110,240,1);
      background: linear-gradient(270deg, rgba(255,255,255,0.4) 0%,rgba(255,255,255,1) 100%);
      border-radius: 8px 8px 0 8px;
      padding: 0 10px;
      @include font-family-jd;
    }
    .tips-content {
      padding-left: 12px;
    }
    .tips-button {
      cursor: pointer;
      padding-left: 40px;
      .tips-button-content {
        display: inline-block;
      }
      .tips-button-icon {
        width: 14px;
        height: 14px;
        margin-left: 5px;
        margin-top: -2px;
        display: inline-block;
        vertical-align: middle;
        background-image: url('../../../assets/front/Lui-icon-arrow-cycle-right.png');
        background-size: cover;
        line-height: 14px;
      }
    }
    .tips-close {
      height: 20px;
      width: 20px;
      right: 0;
      top: 6px;
      cursor: pointer;
      position: absolute;
      display: inline-block;
      vertical-align: middle;
      background-image: url('../../../assets/front/Lui-icon-close-min.png');
      background-size: cover;
    }
  }
}

</style>
