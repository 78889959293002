<template>
  <InToTop v-if="!isInternational" />
  <OuToTop v-else />
</template>

<script>
import InToTop from "./inToTop/index.vue";
import OuToTop from "./ouToTop/index.vue";
import isInternational from '@/utils/isInternational';
export default {
  name: "ToTop",
  components: {
    InToTop,
    OuToTop,
  },
  methods: {
    handleScroll() {
      //首先修改相对滚动位置
      this.scrollTop = this.scrollTop =
        window.pageYOffset || document.body.scrollTop;
      if (this.scrollTop > 300) {
        this.toTopShow = true;
      } else {
        this.toTopShow = false;
      }
    },
  },
  computed: {
    isInternational() {
      return isInternational()
    }
  }
};
</script>