import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule,
} from 'vuex-module-decorators'
import store from '@/store/international/index'
import { SappState } from '@/store/international/getters'

@Module({ dynamic: true, store, name: 'sapp' })
class Sapp extends VuexModule implements SappState {
  public sidebar = {
    opened: true,
    showSidebar: true,
  };
  approvalNumber = 0;
  messageDocListLen = 0;
  apiDocListLen = 0;

  public menus: any = [];

  // 是否显示tab 默认显示
  public isNavbarFixed = true;

  public keyToReload =  0

  @Mutation
  public CHANGE_KEY(key: number) {
    this.keyToReload = key
  }
  @Mutation
  public SET_NAVBAR_FIXED(flag: boolean) {
    this.isNavbarFixed = flag
  }

  @Mutation
  private SHOW_SIDEBAR() {
    this.sidebar.showSidebar = !this.sidebar.showSidebar
  }

  @Mutation
  private TOGGLE_SIDEBAR() {
    this.sidebar.opened = !this.sidebar.opened
  }

  @Mutation
  public SET_Message_Doc_List_Len(len: number) {
    this.messageDocListLen = len;
  }

  @Mutation
  public SET_Approval_Number(num: number) {
    this.approvalNumber = num;
  }

  @Mutation
  public SET_Api_Doc_List_Len(len: number) {
    this.apiDocListLen = len;
  }

  @Action
  public showSideBar() {
    this.SHOW_SIDEBAR()
  }

  @Action
  public toggleSideBar() {
    this.TOGGLE_SIDEBAR()
  }
}

const SappModule: any = getModule(Sapp)
export default SappModule
