export default {
  extranetHost: 'uat-open.cloud.jdl.com',
  buildEnv: 'uat',
  baseEnv: 'uat',
  baseStreamAccount: 'JA2020_1222753',
  baseStreamCloudAccount: 'JA2020_1222753',
  glscDomain: 'https://pulsar-login1.jdl.com',
  gatewayPtnr: '{"appid":"lop_erp"}',
  gatewayPartnerMessageXmlBucket: '',
  gatewayUploadLopDn: "test.sdkfile.com",
  gatewayPartnerMessageLopDn: '',
  docDescription: '/apiNewHand/7',
  docApiPublish: '/apiNewHand/9',
  docApiTest: 'devSupport/32',
  docSdkCreate: 'devSupport/34',
  docMessageListTag: 'devSupport/52791',
  idaasModifyPhone: 'https://pre-account.jdl.com/static/modifyPhone.html?source=&tc=&returnUrl=',
  gatewayApiDecouplingLop: 'uat.lop.open.apidepot',
  gatewayLogDecouplingLop: ''
}
