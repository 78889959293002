const configEnvMap = {
  // prod
  'th.lcp.jdl.com': {
    env: 'prod',
    site: 'us'
  },
  'hl.lcp.jdl.com': {
    env: 'prod',
    site: 'eu'
  },
  'open.cloud.jdl.com': {
    env: 'prod',
    site: 'common'
  },
  'iopen.jdl.com': {
    env: 'prod',
    site: 'common'
  },

  // uat
  'uat-th.lcp.jdl.com': {
    env: 'uat',
    site: 'us'
  },
  'uat-hl.lcp.jdl.com': {
    env: 'uat',
    site: 'eu'
  },
  'uat-open.cloud.jdl.com': {
    env: 'uat',
    site: 'common'
  },
  'uat-open1-cloud.jdl.com': {
    env: 'uat',
    site: 'common'
  },

  // 以下域名仅供研发启动使用
  'uat-th1.lcp.jdl.com': {
    env: 'uat',
    site: 'us'
  },
  'uat-th2.lcp.jdl.com': {
    env: 'uat',
    site: 'us'
  },
  'uat-hl1.lcp.jdl.com': {
    env: 'uat',
    site: 'eu'
  },
  'uat-open1.cloud.jdl.com': {
    env: 'uat',
    site: 'common'
  },
}
export default configEnvMap
