// 统一登录入口处
import { MessageBox } from '@lui/lui-ui'
import { removeToken } from './removeToken'
import { getEnvLoginConfig } from './config'
import { getLoginType } from './login/index'
import store from '@/store'


let showLogTips = false
const login = () => {
  const { code, intranet } = getEnvLoginConfig()
  // removeToken()
  getLoginType(code, intranet)
}

const logout = () => {

}

const reLogin = () => {
  showLogTips = true
  store.commit("SET_LOGINSTATE", false);
  MessageBox.confirm('您的登录状态已失效，是否重新登录？', '提示', {
    confirmButtonText: '登录',
    cancelButtonText: '不登录',
    type: 'warning',
  })
    .then(() => {
      showLogTips = false
      login()
    })
    .catch(() => {
      showLogTips = false
    })
}

export {
  login,
  reLogin
}
