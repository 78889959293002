<template>
  <el-dropdown
    class="site-and-language-switching-dropdown"
    @command="onCommandDropdown"
  >
    <span class="el-dropdown-link">
      {{ computeLanguageAndSiteText }}
      <i class="el-icon-arrow-down el-icon--right"></i>
    </span>
    <template #dropdown>
      <el-dropdown-menu  :key="keyToReload" class="site-and-language-switching-dropdown__menu">
        <div class="menu__dropdown-menu-item-wrapper">
          <div
            v-for="{ title, itemList } in dropdownMenuItemList"
            :key="title"
            class="dropdown-menu-item-wrapper__item"
          >
            <p class="item__title">{{ title }}</p>
            <el-dropdown-item
              v-for="{ command, label } in itemList"
              :key="label"
              :command="command"
              class="item__content"
              :class="{ activated: judgeItemContentClassName(command, title) }"
              >{{ label }}</el-dropdown-item
            >
          </div>
        </div>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import { UserModule } from "@/store";
import store from "@/store";
import { mapState } from "vuex";

export default {
  name: "SiteAndLanguageSwitchingDropdown",
  data() {
    return {
      dropdownMenuItemList: [
        {
          title: this.$t("SiteAndLanguageSwitchingDropdown.index.5pcf56btll80"),
          itemList: [
            { command: "eu", label: "Europe" },
            { command: "us", label: "United States" },
          ],
        },
        {
          title: this.$t("SiteAndLanguageSwitchingDropdown.index.5pcf56btzdc0"),
          itemList: [
            {
              command: "zh-cn",
              label: this.$t(
                "SiteAndLanguageSwitchingDropdown.index.5pcf56btzn40"
              ),
            },
            { command: "en-us", label: "English" },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState("app", {
      keyToReload: (state) => state.keyToReload,
    }),
    computeLanguageAndSiteText() {
      const {
        $i18n: { locale },
      } = this;
      const { site } = UserModule;
      const hashMapLocale = new Map()
        .set(
          "zh-cn",
          this.$t("SiteAndLanguageSwitchingDropdown.index.5pcf56btzn40")
        )
        .set("en-us", "English");
      const hashMapSite = new Map()
        .set(
          "us",
          this.$t("SiteAndLanguageSwitchingDropdown.index.5pcf56btzt40")
        )
        .set(
          "eu",
          this.$t("SiteAndLanguageSwitchingDropdown.index.5pcf56btzy00")
        );

      const languageText = hashMapLocale.get(locale) || "";
      const siteText = hashMapSite.get(site) || "";
      return `${languageText} · ${siteText}`;
    },
  },
  methods: {
    judgeItemContentClassName(command, title) {
      const {
        $i18n: { locale },
      } = this;
      const { site } = UserModule;
      const hashMapTitle = new Map()
        .set(
          this.$t("SiteAndLanguageSwitchingDropdown.index.5pcf56btll80"),
          command === site
        )
        .set(
          this.$t("SiteAndLanguageSwitchingDropdown.index.5pcf56btzdc0"),
          command === locale
        );
        if(title === '站点' || title === 'Sites') {
          return hashMapTitle.get('站点') || hashMapTitle.get('Sites') ;
        } else if(title === '语言' || title === 'Language') {
          return hashMapTitle.get('语言') || hashMapTitle.get('Language');
        }
      
    },
    onCommandDropdown(command) {
      const setSite = () => {
        UserModule.SET_SITE(command);
        // UserModule.startLoading();
        // location.reload();
        UserModule.CHANGE_SITE(command)
      };
      const set$locale = () => {
        UserModule.SET_LOCALE(command);
        this.$i18n.locale = command;
        store.commit("app/CHANGE_KEY", Math.floor(Math.random() * 9000000000));
        // UserModule.startLoading();
        // location.reload();
      };
      const hashMapCommand = new Map()
        .set("eu", setSite)
        .set("us", setSite)
        .set("zh-cn", set$locale)
        .set("en-us", set$locale);
      hashMapCommand.get(command)();
    },
  },
};
</script>

<style lang="scss" >
.site-and-language-switching-dropdown__menu {
  .menu__dropdown-menu-item-wrapper {
    display: flex;
    padding: 8px;

    .dropdown-menu-item-wrapper__item {
      &:nth-child(1) {
        border-right: 1px solid #f5f5f5;
      }

      .item__title {
        margin-bottom: 8px;
        padding: 0 10px;
      }

      .item__content {
        &.activated {
          color: #3c6ef0;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.site-and-language-switching-dropdown {
  margin: 0 8px;
  color: #333;
}
</style>