import Vue from "vue";
import ElementUI from "@lui/lui-ui";
import "@lui/lui-ui/lib/theme-chalk/index.css";
import App from "pages/international/App.vue";
import store from "@/store";
import router from "@/router/international/index";
import VueClipboard from "vue-clipboard2";
import i18n from "@/locales/index";
import dayjs from 'dayjs'
import envConfig from '@/env/index.js'

window.env = envConfig;
Vue.use(ElementUI, { i18n: (key: any, value: any) => i18n.t(key, value) });
Vue.use(VueClipboard);
Vue.config.devtools = true;

Vue.config.productionTip = true;
window.eventHub = new Vue();
Vue.prototype.$dayjs = dayjs

if (
  sessionStorage.getItem("store") &&
  sessionStorage.getItem("store") != null
) {
  store.replaceState(
    Object.assign(
      {},
      store.state,
      JSON.parse(<string>sessionStorage.getItem("store"))
    )
  );
}

// const site = localStorage.getItem("site");
const locale = localStorage.getItem("locale");
// site && store.commit("SET_SITE", site);
locale && store.commit("SET_LOCALE", locale);

// 更新站点信息
store.dispatch('UPDATE_SITE_FROM_PATHNAME');

window.addEventListener("beforeunload", () => {
  sessionStorage.setItem("store", JSON.stringify(store.state));
});

new Vue({
  router,
  store,
  i18n,
  render: (h: (arg0: any) => any) => h(App),
}).$mount("#app");
