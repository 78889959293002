<template>
  <div class="to-top">
    <div class="aside-fixed" ref="fixed">

        <el-popover
          placement="left"
          trigger="hover"
          popper-class="aside-text-popper"
          >
          <div class="aside-text-popper-ctx">
            <div class="aside-text-popper-line-bg"></div>
            <div class="line" :clstag="clstag('Management_1702468662190|43', '')">
              <span>研发值班账号：</span>
              <span>org.lcp1</span>
              <a href="timline://chat/?topin=org.lcp1" :underline="false" target="_self" class="icon"></a>
            </div>
            <div class="line">
              <span>产品：</span>
              <span :clstag="clstag('Management_1702468662190|44', '')">zhangjuzheng3</span>
              <a href="timline://chat/?topin=zhangjuzheng3" :underline="false" target="_self" class="icon"></a>
              <!-- <div style="margin-top: 12px;" :clstag="clstag('Management_1702468662190|45', '')">
                <span class="pad">dongguojun1</span>
                <a href="timline://chat/?topin=dongguojun1" :underline="false" target="_self" class="icon"></a>
              </div> -->
            </div>
            <div class="line" :clstag="clstag('Management_1702468662190|46', '')">
              <span>平台功能问题咚咚群：</span>
              <span>82490630</span>
              <a href="timline://chat/?topin=82490630&type=2" :underline="false" target="_self" class="icon"></a>
            </div>
            <!-- <div class="line" :clstag="clstag('Management_1702468662190|47', '')">
              <span>供应链标准客户导入群：</span>
              <span>1026841077</span>
              <a href="timline://chat/?topin=1026841077&type=2" :underline="false" target="_self" class="icon"></a>
            </div>
            <div class="line" :clstag="clstag('Management_1702468662190|48', '')">
              <span>快快标准客户导入群：</span>
              <span>10203682078</span>
              <a href="timline://chat/?topin=10203682078&type=2" :underline="false" target="_self" class="icon"></a>
            </div> -->
          </div>
          <div class="tool customer-service" slot="reference">
            <div class="text" >联系我们</div>
          </div>
        </el-popover>

        <el-popover
          placement="left"
          trigger="hover"
          popper-class="aside-text-popper"
          >
          <div class="aside-text-popper-ctx">
            <div class="line" @click="toFeedback" :clstag="clstag('Management_1702468662190|49', '')">
              <span>点击跳转</span>
              <span style="color: #3C6EF0;">良言</span>
              <span>反馈您的问题</span>
            </div>
          </div>
          <div class="tool feed-back" slot="reference">
            <div class="text" >意见反馈</div>
          </div>
        </el-popover>
    </div>
  </div>
</template>

<script>
import pageClick from '@/utils/pageClick'

export default {
  name: 'ToTop',
  data() {
    return {
      toTopShow: false,
      contactVisible: false,
      contactInnerVisible: false,
      timer: null,
      // 控制调查问卷弹框
      questionnaireVisible: false,
    }
  },
  methods: {
    // 点击事件埋点
    clstag(intranetId, extranetId) {
      return pageClick(intranetId, extranetId)
    },
    toFeedback() {
      window.open('//get.jd.com/#/survey/index?id=6953843047407083&erp=type', '_blank')
    },
    resizeFixed() {
      const app = document.querySelector('#app')
      const { fixed } = this.$refs
      fixed.style.right =
        app.clientWidth > 1480
          ? `${parseInt((app.clientWidth - 1200) / 2, 10) - 40}px`
          : '20px'
      if (!this.timmer) {
        this.timer = setTimeout(() => {
          this.timer = null
          fixed.style.right =
            app.clientWidth > 1480
              ? `${parseInt((app.clientWidth - 1200) / 2, 10) - 40}px`
              : '20px'
        }, 300)
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.to-top {
  .aside-fixed {
    position: fixed;
    top: 75%;
    bottom: 100px;
    transform: translateY(-50%);
    right: 20px;
    z-index: 3;
    width: 52px;
    height: 130px;
    background: linear-gradient(180deg, rgba(194,231,255,0.8) 0%,rgba(219,233,255,0.8) 20.12086591475801%,rgba(242,251,255,0.8) 100%);
    border: 2px solid rgba(255,255,255,1);
    border-radius: 27px;
    .tool {
      width: 40px;
      height: 38px;
      font-size: 10px;
      line-height: 10px;
      color: #525765;
      &:hover {
        color: #3C6EF0;
      }
      cursor: pointer;
      background-size: 20px 20px;
      background-position: 10px 0px;
      margin: auto;
      margin-top: 17px;
      position: relative;
      .text {
        position: absolute;
        bottom: 0;
        // width: 40px;
        font-size: 10px;
        letter-spacing: 0px !important;
        white-space: nowrap !important;
        word-wrap: normal;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
    }
    .customer-service {
      background-image: url("~@/assets/front/Lui-icon-earphone-normal.png");
      background-repeat: no-repeat;
      &:hover {
        background-image: url("~@/assets/front/Lui-icon-earphone-active.png");
      }
    }
    .feed-back {
      background-image: url("~@/assets/front/el-icon-feedback-normal.png");
      background-repeat: no-repeat;
      &:hover {
        background-image: url("~@/assets/front/el-icon-feedback-active.png");
      }
    }
  }
}
</style>
<style lang="scss">
.aside-text-popper {
  background: #FFF;
  padding: 0px;
  border-radius: 8px;
  border: 2px solid #fff;
  .aside-text-popper-ctx {
    width: max-content;
    font-size: 14px;
    line-height: 20px;
    padding: 16px 22px;
    padding-top: 2px;
    border-radius: 8px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 30px; /* 渐变区域宽度 */
      height: 20px; /* 渐变区域高度 */
      background: linear-gradient(to bottom right,
      rgba(218,224,247,0.2) 0%,
      rgba(18,105,255,0) 75%
      );
      border-radius: 8px 0 0 0 ;
      z-index: -1;
    }
    .line {
      font-size: 14px;
      line-height: 20px;
      color: #525765;
      cursor: pointer;
      padding-top: 12px;
      .icon {
        width:  19px;
        height: 18px;
        margin-left: 8px;
        display: inline-block;
        vertical-align: text-top;
        background-image: url("~@/assets/front/dongdong-normal.png");
        background-size: cover;
        &:hover{
          background-image: url("~@/assets/front/dongdong-active.png");

        }
      }
      .pad {

        padding-left: 42px;
      }
    }
  }
}
</style>
