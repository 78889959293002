
import { UserModule } from '@/store/index'

const extranetLogin = async () => {
  await UserModule.extranetCheckLogin();
}

export {
  extranetLogin
}
