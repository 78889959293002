<template>
  <div class="fixed-box" ref="fixed">
    <div class="aside-fixed">
      <!-- <div class="aside-fixed-box">
        <a href="//open.jdl.com/#/question/1" target="_blank">
          <div class="aside-fixed-box-icon">
            <span class="top-icon-t_question"></span>
          </div>
          <h5>{{ $t("toTop.index.5iwdv6ex8000") }}</h5>
        </a>
      </div> -->
      <!-- <hr /> -->
      <div
        class="aside-fixed-box link-us"
        @mouseenter="
          contactInnerVisible = true;
          contactOuterVisible = true;
        "
        @mouseleave="
          contactInnerVisible = false;
          contactOuterVisible = false;
        "
      >
        <div class="aside-fixed-box-icon">
          <span class="top-icon-t_phone"></span>
        </div>
        <h5>{{ $t("toTop.index.5iwdv6ex8lg0") }}</h5>
        <!-- 内网展示联系我们浮窗 -->
        <InnerConnectUsDialog :visible="isIntranet && contactInnerVisible" />
        <!-- 外网展示联系我们浮窗 -->
        <OuterConnectUsDialog :visible="!isIntranet && contactOuterVisible" />
      </div>
      <!-- <hr /> -->
      <!-- <div class="aside-fixed-box">
        <a
          href="//get.jd.com/#/survey/index?id=4199982330614988"
          target="_blank"
        >
          <div class="aside-fixed-box-icon">
            <span class="top-icon-t_editor"></span>
          </div>
          <h5>{{$t('toTop.index.5iwdv6ex8pc0')}}</h5>
        </a>
      </div> -->
    </div>
    <div
      v-show="toTopShow"
      class="el-icon-arrow-up to-top"
      @click="scrollToTop"
    ></div>
  </div>
</template>

<script>
import InnerConnectUsDialog from "./components/InnerConnectDialog.vue";
import OuterConnectUsDialog from "./components/OuterConnectDiloag.vue";
export default {
  name: "OuToTop",
  props: ["isIntranet"],
  components: {
    InnerConnectUsDialog,
    OuterConnectUsDialog,
  },
  data() {
    return {
      toTopShow: false,
      contactVisible: false,
      contactInnerVisible: false,
      contactOuterVisible: false,
      timer: null,
      //控制调查问卷弹框
      questionnaireVisible: false,
    };
  },
  methods: {
    handleScroll() {
      //首先修改相对滚动位置
      this.scrollTop = this.scrollTop =
        window.pageYOffset || document.body.scrollTop;
      if (this.scrollTop > 300) {
        this.toTopShow = true;
      } else {
        this.toTopShow = false;
      }
    },
    scrollToTop() {
      // let timer = null,
      //   _that = this;
      // //动画，使用requestAnimationFrame代替setInterval
      // cancelAnimationFrame(timer);
      // timer = requestAnimationFrame(function fn() {
      //   if (_that.scrollTop > 0) {
      //     _that.scrollTop -= 100;
      //     //然后修改这里实现动画滚动效果
      //     document.body.scrollTop = document.documentElement.scrollTop =
      //       _that.scrollTop;
      //     timer = requestAnimationFrame(fn);
      //   } else {
      //     cancelAnimationFrame(timer);
      //     _that.toTopShow = false;
      //   }
      // });
      // 瞬间回到顶部
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    },
    resizeFixed(allowTimer = true) {
      const app = document.querySelector("#app");
      const fixed = this.$refs["fixed"];
      fixed.style.right =
        // 如果大于内容宽度 1200 并且超过本身宽度 64 * 2
        // 因为 margin auto 所以需要 * 2
        // (1280 + 64 * 2) = 1408
        // (app.clientWidth - 1408) / 2 单侧 margin
        // + 20 左侧离内容区域的边距
        app.clientWidth > 1408
          ? `${parseInt((app.clientWidth - 1408) / 2) + 20 + 17}px`
          : "0px";
      // 28px 间距
      if (allowTimer && !this.timer) {
        this.timer = setTimeout(() => {
          this.timer = null;
          this.resizeFixed(false);
        }, 300);
      }
    },
    jumpURL() {
      window.open("https://get.jd.com/#/survey/index?id=60971", "_blank");
    },
  },
  mounted() {
    this.$nextTick(function () {
      //修改事件监听
      window.addEventListener("scroll", this.handleScroll);
    });
    // this.resizeFixed(false);
    // window.addEventListener("resize", this.resizeFixed);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    // window.removeEventListener("resize", this.resizeFixed);
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/mixin.scss";
@import "~@/assets/styles/top-font.scss";
.fixed-box {
  position: fixed;
  bottom: 100px;
  right: 0px;
  z-index: 6;
  height: 120px;
  .aside-fixed {
    width: 64px;
    height: 68px;
    background-color: #f5f7ff;
    // background: linear-gradient(90deg, rgba(214,231,255,1) 0%,rgba(201,221,255,1) 100%);
    border-radius: 6px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 2, 0.12);
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;

    &:lang(zh) {
      width: 64px;
    }

    &:lang(en) {
      padding-top: 8px;
      padding-bottom: 8px;
      width: 77px;
    }

    .aside-fixed-box {
      width: 100%;
      height: 66px;
      cursor: pointer;
      transition: all 0.3s;

      &.link-us:lang(en) {
        padding-bottom: 16px;
      }

      &:hover {
        background: linear-gradient(
          180deg,
          rgba(60, 110, 240, 1) 0%,
          rgba(110, 154, 255, 1) 100%
        );
        .aside-fixed-box-icon {
          color: white;
        }
        h5 {
          color: white;
        }
      }
      .aside-fixed-box-icon {
        @include transition-color;
        height: 33px;
        background-size: 18px 18px;
        color: #83a6ff;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 5px;
      }
      a {
        width: 100%;
        height: 100%;
        display: block;
      }
      h5 {
        @include transition-color;
        height: 17px;
        font-size: 12px;
        line-height: 17px;
        font-weight: 500;
        text-align: center;
        color: #3c6ef0;
      }
    }
  }
  .to-top {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -19px;
    width: 38px;
    height: 38px;
    background: linear-gradient(
      90deg,
      rgba(214, 231, 255, 1) 0%,
      rgba(201, 221, 255, 1) 100%
    );
    opacity: 76;
    text-align: center;
    line-height: 38px;
    font-size: 20px;
    color: white;
    transition: all 0.28s;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      background: linear-gradient(
        180deg,
        rgba(60, 110, 240, 1) 0%,
        rgba(110, 154, 255, 1) 100%
      );
      opacity: 70;
    }
  }
}
</style>