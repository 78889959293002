<template>
  <div class="footer cbox">
    <div class="footer-main">
      <div class="link-wrap vbox">
        <a class="link" @click="gotoOuterOpen" target="_blank" :clstag="clstag('Management_1702468662190|35', '')">京东物流开放平台</a>|
        <a class="link" href="//www.jdl.com" target="_blank" :clstag="clstag('Management_1702468662190|36', '')">京东物流</a>
        <span v-if="computeIsIntranet">|</span>
        <router-link tag="a" target="_blank" to="/protocol" class="link" :clstag="clstag('Management_1702468662190|37', '')"
          >隐私协议</router-link
        >|
        <router-link tag="a" target="_blank" to="/helpDocument" class="link" :clstag="clstag('Management_1702468662190|38', '')"
          >文档中心（旧）</router-link
        >|
        <router-link tag="a" target="_blank" to="/apiStore?interfaceType=jsf" class="link" :clstag="clstag('Management_1702468662190|39', '')"
          >API仓库（旧）</router-link
        >
      </div>
      <p class="tip">
        <span>©2004 - {{ getYears() }}</span>
        <span>京东物流</span>
        <span>版权所有</span>
        <span>增值电信业务经营许可证：京ICP备20010510号-7</span>
        <span class="icon"></span>
        <span>京公网安备 11011502005548号</span>
      </p>
    </div>
  </div>
</template>

<script>
import isIntranet from '@/utils/isIntranet'
import isInternational from '@/utils/isInternational'
import pageClick from '@/utils/pageClick'

export default {
  computed: {
    computeIsIntranet() {
      return isIntranet()
    },
    computeInternational() {
      return isInternational()
    },
  },
  methods: {
    // 点击事件埋点
    clstag(intranetId, extranetId) {
      return pageClick(intranetId, extranetId)
    },
    getYears() {
      return new Date().getFullYear();
    },
    gotoOuterOpen() {
      const url = this.computeInternational ? 'http://open.cloud.jdl.com' : 'https://open.jdl.com'
      console.log('url', url)
      window.open(url,'_blank')
    },
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  min-width: 1366px;
  height: 136px;
  margin-top: -136px;
  font-size: 14px;
  background: #333;
  .footer-main {
    width: 1200px;
    margin: 0 auto;
    color: #EFEFEF;
    .link-wrap {
      color: #EFEFEF;
      .link {
        font-size: 18px;
        line-height: 28px;
        margin: 0 40px;
        color: #EFEFEF;
        &:hover {
          color: #3c6ef0;
        }
      }
      a {
        vertical-align: middle;
      }
    }
    .tip {
      color: #FFFFFF;
      font-size: 13px;
      line-height: 16px;
      padding-top: 20px;
      text-align: center;
      span {
        padding: 0 5px;
      }
      .icon {
        width: 20px;
        height: 21px;
        padding: 0;
        display: inline-block;
        vertical-align: sub;
        margin-left: 34px;
        background-image: url("~@/assets/front/beian.png");
        background-size: cover;
      }
    }
  }
}
</style>
