import request_msg from "@/utils/request_msg";

export interface AutoBots {
    [key: string]: any;
}

const autobots = {
    /**
     * 问答机器人欢迎语
     * @param data traceId
     * @returns 
     */
    baseInfo: (params: any) =>
        request_msg.get("/autoBots/baseInfo", { params }),
    /**
     * 推荐问题
     * @param data 
     * @returns 
     */
    suggestionQuery: (data: any) =>
        request_msg.post("/autoBots/suggestionQuery", data),
    /**
     * 
     * @param data 机器人会话
     * @returns 
     */
    searchAi: (data: any) =>
        request_msg.post("/autoBots/searchAi", data),
};

export default autobots;