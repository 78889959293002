<template>
  <el-dropdown class="switch-site-wrap" trigger="click" @command="handleChangeSite">
      <span class="el-dropdown-link">
          {{ siteInfo.label }}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="site in usefulSiteList" :command="site.host" :key="site.host">{{ site.label }}</el-dropdown-item>
      </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
import { UserModule } from '@/store'

export default {
  props: {},
  data() {
      return {

      }
  },
  computed: {
      siteInfo() {
          return UserModule.currentSiteInfo
      },
      usefulSiteList() {
          const curHost = this.siteInfo.host;
          if (window.$isNewHost) {
              return UserModule.siteList.filter(siteItem => siteItem.host.startsWith(window.location.hostname));
          } else {
              const curHostTpeyList = curHost.split('.')[0].split('-');
              if (curHostTpeyList.length > 1) {
                  const curHostType = curHostTpeyList[0];
                  const isUat = String(location.href).includes('uat')
                  if(isUat) {
                    return UserModule.siteList.filter(
                      siteItem => siteItem.host.includes(curHostType) && 
                      !siteItem.host.includes('admin-open') && 
                      !siteItem.host.includes('1') &&
                      !siteItem.host.includes('2')
                    );
                  } else {
                    return UserModule.siteList.filter(siteItem => siteItem.host.includes(curHostType) && !siteItem.host.includes('admin-open'));
                  }
              } else {
                  return UserModule.siteList.filter(siteItem => !siteItem.host.includes('-'));
              }
          }
      }
  },
  mounted() {

  },
  methods: {
      handleChangeSite(siteHost) {
          // 新域名切换站点通用方法
          if(window.$isNewHost) {
              const site = new URL(`http://${siteHost}`).pathname.replaceAll('/', '')
              // UserModule.CHANGE_SITE(site);
              UserModule.LogOutChangeSiteHost(siteHost);
          } else {
              // 老域名走老逻辑
              UserModule.LogOutChangeSiteHost(siteHost);
          }
      }

  },
  name: "SwitchSite"
}
</script>
<style lang="scss" scoped>
  .switch-site-wrap {
      margin-right: 12px;
      .el-dropdown-link {
          color: #23252B;
          border: 1px solid #23252B;
          border-radius: 18px;
          padding: 6px;
          cursor: pointer;
      }
  }
</style>