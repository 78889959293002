import isInternational from './isInternational'
// 根据环境变量判断是否为内网
const hostnameList: string[] = [
  "dev-test-lcp.jdl.com",
  "test-lcp.jdl.com",
  "uat-lcp.jdl.com",
  "lcp.jdl.com",
  "uat-admin-open.jdl.com",
  "admin-open.jdl.com",

  "uat-th.lcp.jdl.com", 
  "uat-hl.lcp.jdl.com", 

  "uat-th2.lcp.jdl.com", // 仅做本地启动
  "uat-th1.lcp.jdl.com", // 仅做本地启动
  "uat-hl1.lcp.jdl.com", // 仅做本地启动

  "th.lcp.jdl.com",
  "hl.lcp.jdl.com"
];

const isIntranet = () => {
  const currentHostname: string = window.location.hostname;
  return hostnameList.includes(currentHostname);
};

export const isExtranetAndGlobal = () => {
  const currentHostname: string = window.location.hostname;
  return !hostnameList.includes(currentHostname) && (currentHostname.indexOf('cloud.jdl.com') > -1)
};

export default isIntranet;
