import { intranetLogin } from './common/intranetLogin'
import { extranetLogin } from './common/extranetLogin'
import { usIntranetLogin } from './common/usIntranetLogin'
import { glscLogin } from './common/glscLogin'

const loginActions: { [key: string]: { [key: number]: () => Promise<void> } } = {
  intranet: {
    1: intranetLogin,
    3: usIntranetLogin,
    4: usIntranetLogin,
  },
  extranet: {
    2: extranetLogin,
    5: glscLogin,
  },
}

const getLoginType = (code: number, intranet: boolean): void => {

  const loginType = intranet ? 'intranet' : 'extranet'
  const loginAction = loginActions[loginType][code]
  
  if (loginAction) {
    loginAction()
  }
}

export { getLoginType }
