import common from './common'
import eu from './eu-config'
import us from './us-config'
import configEnvMap from './config'

let env = common

if (
  configEnvMap[window.location.hostname] && 
  configEnvMap[window.location.hostname].site === 'us'
) {
  env = Object.assign(common, us)
} else if (
  configEnvMap[window.location.hostname] && 
  configEnvMap[window.location.hostname].site === 'eu'
) {
  env = Object.assign(common, eu)
}
export default env
