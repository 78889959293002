import Vue from "vue";
import Router, { RouteConfig, Route, NavigationGuardNext } from "vue-router";
import { UserModule } from "@/store/index";
import isIntranet from "@/utils/isIntranet";
import intranetRoutes from "@/router/international/intranetRoutes";
import extranetRoutes from "@/router/international/extranetRoutes";
import { glscLogin } from '@/utils/oauth/login/common/glscLogin'
// import MessageBoxImportantNotice from "@/utils/MessageBoxImportantNotice.js";

Vue.use(Router);

const baseRoutes: Array<RouteConfig> = [
  { path: "/404", component: () => import("@/views/404.vue") },
  { path: "*", redirect: "/404" },
];

// 根据内外网环境加载不同路由表
const routes: RouteConfig[] = isIntranet()
  ? baseRoutes.concat(intranetRoutes)
  : baseRoutes.concat(extranetRoutes);

const router = new Router({
  mode: "hash", // 推荐使用 history 便于埋点统计且地址栏路径较直观
  routes,
});
// 强制登录
const needLoginPathTable: Array<string> = [
  "ApiTestMainPage",
  "Apply",
  "MySystem",
  "Oauth",
];
router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
  document.documentElement.scrollTop = 0;

  UserModule.SET_CURRENT_SITE_INFO(window.location.hostname);
  if (needLoginPathTable.includes(`${to.name}`)) {
    // 关闭Loading
    UserModule.is_loading && UserModule.closeLoading();
    await UserModule.CheckLogin();
    next();
  } else {
    if (isIntranet()) {
      window.location.hash.indexOf("ticket") > -1 &&
        (await UserModule.CheckLogin());
      next();
    } else {
      const { name, query, params } = to;
      const { site } = query;
      const needSwitchSiteRouteNameList = ["AccessGuideDetail", "AccessGuide"];
      const siteList = ["us", "eu"];
      const currentRouteNameListIncludedOrNot =
        needSwitchSiteRouteNameList.includes(`${name}`);
      const currentSite = UserModule.site;
      const siteListIncludedOrNot = siteList.includes(`${site}`);

      if (currentRouteNameListIncludedOrNot && site) {
        if (siteListIncludedOrNot) {
          if (site !== currentSite) {
            UserModule.SET_SITE(`${site}`);
          }
        }
        const routerParams: any = { name, params };
        next(routerParams);
      } else {
        glscLogin()
        next();
      }
    }
  }
});

export default router;
