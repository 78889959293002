import isIntranet from './isIntranet'
import isInternational from './isInternational'

const AccountType = () => {
  if (isIntranet()) {
    return 'erp'
  }
  if (isInternational()) {
    return 'glsc'
  }
  return 'idaas'
}

export default AccountType
