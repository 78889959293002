export default {
  intranetHost: 'uat-hl.lcp.jdl.com',
  gatewayOuterBaseApi: 'http://uat-intl-holland-lop.jingdonglogistics.com/',
  gatewayBaseApi: 'http://uat-intl-holland-lop.jingdonglogistics.com/',
  gatewayFileBaseApi: 'http://uat-intl-holland-lop.jingdonglogistics.com/',
  gatewayUploadLopDn: '',
  gatewayUploadLopDnXml: 'uat.xml.upload.jdl.com',
  domainErp: '//ssa.ochama.com',
  gatewayApiDecoupling: 'http://uat-intl-holland-lop.jingdonglogistics.com',
  jingMeAuthAlias: 'lop-holland-intl-uat'
}
