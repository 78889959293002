import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators'
import Cookies from 'js-cookie'
import { Message } from '@lui/lui-ui'
import jsonp from 'jsonp'
import Vue from 'vue'
import { AxiosRequestConfig } from 'axios'
import store, { ISiteInfo } from '@/store/international'
import { IUserState } from '@/store/international/getters'
import Utils from '@/utils/utils'
import request from '@/utils/request'
import isIntranet from '@/utils/isIntranet'
import extranetUser from '@/api/extranet/user'
import i18n from '@/locales/index'

// 兼容需求 假设从控制台点击登录则新开页面进行登录
// 其余情况 params仍然取值为 gotoUrl
type params = {
  gotoUrl: string,
  openBlank?: boolean
} 
@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  // 区域语言
  public locale =
    localStorage.getItem('locale') || isIntranet() ? 'zh-cn' : 'en-us';
  // 国际站点
  // public site = localStorage.getItem("site") || "us";
  public site = '';
  public consoleSite = 'intl-admin'
  /**
   * 登录账号类型，默认的是erp，还有一个是 glsc
   */
  public accountType = 'erp';
  /**
   * 名字
   */
  public name = '';
  /**
   * 账号头像地址
   */
  public headImg = '';
  /**
   * ERP单点登录获得的临时态
   */
  public ssoServiceTicket = '';
  /**
   * 是否登录 默认时false
   */
  public loginState = false;
  /**
   * 用户的pin，外网核内网共用
   */
  public user_pin = '';
  /**
   * 是否时开发者，默认时no
   */
  public user_develop = 'no';
  /**
   * 是否显示正在加载
   */
  public is_loading = false;
  // --产生的回调地址属性
  public redirectUrl = '';

  /**
   * 行为类型 1:登录 2:注册
   */
  public actionType = 1;

   /**
   * 登录用户名
   */
  public loginUsername = '';

  /**
   * 角色类型 0:子账号 1:主账号
   */
  public roleType = 0;

  to: any;
  from: any;
  roles: any;
  public currentSiteInfo: ISiteInfo = {
    host: '',
    label: '',
    serverRoom: '',
  };
  public siteList: ISiteInfo[] = [
    {
      host: 'th.lcp.jdl.com',
      label: '美国站',
      serverRoom: 'th-lcp',
    },
    {
      host: 'test-th.lcp.jdl.com',
      label: '美国站',
      serverRoom: 'th-lcp-test',
    },
    {
      host: 'uat-th.lcp.jdl.com',
      label: '美国站',
      serverRoom: 'th-lcp-uat',
    },
    {
      host: 'hl.lcp.jdl.com',
      label: '欧洲站',
      serverRoom: 'hl-lcp',
    },
    {
      host: 'test-hl.lcp.jdl.com',
      label: '欧洲站',
      serverRoom: 'hl-lcp-test',
    },
    {
      host: 'uat-hl.lcp.jdl.com',
      label: '欧洲站',
      serverRoom: 'hl-lcp-uat',
    },
    { // 仅做本地开发使用
      host: "uat-th1.lcp.jdl.com",
      label: "美国站",
      serverRoom: "th-lcp-uat",
    },
    { // 仅做本地开发使用
      host: "uat-th2.lcp.jdl.com",
      label: "美国站",
      serverRoom: "th-lcp-uat",
    },
    {
      host: 'uat-admin-open.jdl.com/eu',
      label: '欧洲站',
      serverRoom: 'hl-lcp-uat', // 没有用到
    },
    {
      host: 'admin-open.jdl.com/eu',
      label: '欧洲站',
      serverRoom: 'hl-lcp', // 没有用到
    },
    {
      host: 'uat-admin-open.jdl.com/us',
      label: '美国站',
      serverRoom: 'th-lcp-uat', // 没有用到
    },
    {
      host: 'admin-open.jdl.com/us',
      label: '美国站',
      serverRoom: 'th-lcp', // 没有用到
    },
  ];

  public menus = [];
  public showRouter = false;

  @Mutation
  public SET_LOCALE(locale: string) {
    this.locale = locale
    localStorage.setItem('locale', locale || '')
  }

  @Mutation
  public SET_SITE(site: string) {
    this.site = site
    localStorage.setItem('site', site || '')
  }

  @Mutation
  private SET_REDIRECTURL(redirectUrl: any) {
    this.redirectUrl = redirectUrl
  }

  @Mutation
  private SET_ACCOUNT_TYPE(accountType: any) {
    this.accountType = accountType
  }

  @Mutation
  private SET_NAME(name: string) {
    this.name = name
  }

  @Mutation
  private SET_USER_PIN(user_pin: string) {
    this.user_pin = user_pin
  }

  @Mutation
  private SET_HEADIMG(headImg: string) {
    this.headImg = headImg
  }

  @Mutation
  private SET_LOADING(is_loading: boolean) {
    this.is_loading = is_loading
  }

  @Mutation
  private SET_SSOSERVICETICKET(ssoServiceTicket: string) {
    this.ssoServiceTicket = ssoServiceTicket
  }

  @Mutation
  public SET_USER_DEVELOP(user_develop: string) {
    this.user_develop = user_develop
  }

  @Mutation
  public SET_LOGINSTATE(loginState: boolean) {
    this.loginState = loginState
  }

  @Mutation
  public SET_ACTIONTYPE(actionType: number) {
    this.actionType = actionType
  }
  @Mutation
  public SET_ROLETYPE(roleType: number) {
    this.roleType = roleType
  }
  @Mutation
  public SET_CURRENT_SITE_INFO(hostName: string) {
    const url = hostName
    const currentSiteInfo = this.siteList.find(
      item => item.host === url
    )
    console.log('currentSiteInfo', currentSiteInfo)
    if (currentSiteInfo) {
      this.currentSiteInfo = currentSiteInfo
    }
  }

  @Mutation
  private SET_LOGINUSERNAME(loginUsername: string) {
    if (loginUsername) this.loginUsername = loginUsername
  }

  /**
   * 清除jdl.cn域下cookies
   */
  @Action
  public async cacheClear(site?: any) {
    const url = site ? `/${site}/cookies/erp/logout` : '/cookies/erp/logout'
    const method = 'post'
    const domain = '.jdl.com'
    const params = { domain }
    const config: AxiosRequestConfig = { url, method, params }
    return await request(config)
  }

  /**
   * 登录操作
   */
  @Action
  public async Login() {
    // 登陆前清除cookie
    await this.cacheClear()
    Cookies.remove('thor')
    Cookies.remove('lcpinfo')
    Cookies.remove('supplierId')
    Cookies.remove('groupCode')
    if (isIntranet()) {
      this.SET_ACCOUNT_TYPE('erp')
      const { domainErp } = window.env
      if (!domainErp) {
        return
      }
      window.location.href =
        `${domainErp}/sso/login?ReturnUrl=${encodeURIComponent(
          window.location.href
        )}` || '' // erp内网 登录开启
    } else {
      this.SET_ACCOUNT_TYPE('glsc')
      window.location.href =
        `//test-soms.jdl.com/static/unifiedLogin.html?orgId=1&&returnUrl=${encodeURIComponent(
          window.location.href
        )}` || ''
    }
  }

  /**
   * 前往控制台
   */
  @Mutation
  public gotoAppManager() {
    const url = `${window.location.origin}/intl-admin/#/appManager`
    window.open(url, '_blank')
  }

  /**
   * 前往控制台成为开发者
   */
  @Action
  async gotoAppCreate() {
    if ('/intl-admin/#/appManager/createApp'.startsWith('http')) {
      window.location.href = '/intl-admin/#/appManager/createApp'
      return
    }
    window.location.href = `http://${window.location.host}/intl-admin/#/appManager/createApp`
  }

  /**
   *  前往发布API
   */
  @Action
  async gotoPublishApi() {
    if ('/intl-admin/#/apiManager/registerApi'.startsWith('http')) {
      window.location.href = '/intl-admin/#/apiManager/registerApi'
      return
    }
    window.location.href = `http://${window.location.host}/intl-admin/#/apiManager/registerApi`
  }

  /**
   * 外网自动入驻
   */
  @Action
  public async saveOutDeveloper() {
    // 获取国际化语言i18n变量
    const messageInfo = i18n.t('store.common.user.5iwdv6ex8pc1')
    const response = await extranetUser.saveOutDeveloper().catch((error) => {
      Message.error(messageInfo)
      throw new Error(error)
    })
    const { data } = response.data
    const { supplierId, develop, actionType, roleType } = data
    // 自动入驻后将supplierId的值存入缓存
    Cookies.set('supplierId', supplierId)
    this.SET_USER_DEVELOP(develop)
    this.SET_ACTIONTYPE(actionType)
    this.SET_ROLETYPE(roleType)
    return response
  }

  /**
   * 进入控制台
   */
  @Action
  public async gotoAdmin() {
    if (isIntranet()) {
      this.SET_ACCOUNT_TYPE('erp')
      const url = '/user/getSelectedSupplierId'
      const method = 'get'
      const config: AxiosRequestConfig = { url, method }
      const response = await request(config).catch((error) => {
        console.log(error)
      })
      if (response) {
        const { data } = response
        // data为-2表示未选择过团队
        const urlHash =
          data === -2 ? '/#/chooseSupplier' : '/intl-admin/#/openBiz'
        window.location.href = `${window.location.origin}${urlHash}`
      }
    } else {
      this.SET_ACCOUNT_TYPE('glsc')
      const url = '/user/getSelectedSupplierId'
      const method = 'get'
      const config: AxiosRequestConfig = { url, method }
      const response = await request(config).catch((error) => {
        console.log(error)
      })
      if (response) {
        const { data } = response
        // data为-2表示未选择过团队
        if (data === -2) {
          // supplierId为-1，不可进入控制台,再次请求自动化入驻接口
          (await this.saveOutDeveloper()) && this.gotoAppManager()
        } else {
          this.gotoAppManager()
          // window.location.href = `${window.location.origin}/intl-admin/#/appManager`
        }
      }
    }
  }

  /**
   * 重置用户信息
   */
  @Action
  public resetUser() {
    this.SET_LOGINSTATE(false)
    this.SET_REDIRECTURL('')
    this.SET_USER_PIN('')
    this.SET_NAME('')
    this.SET_ACCOUNT_TYPE(isIntranet() ? 'erp' : 'glsc')
    this.SET_USER_DEVELOP('no')
    this.SET_HEADIMG('')
    this.SET_LOADING(false)
    this.SET_SSOSERVICETICKET('')
    this.SET_LOGINUSERNAME('')
  }

  /**
   * 将store里的数据存入session缓存
   */
  @Action
  public setSessionStoreage() {
    sessionStorage.setItem('store', JSON.stringify(store.state))
  }

  /**
   * 开始加载页面
   */
  @Action
  public startLoading() {
    this.SET_LOADING(true)
    const vm = new Vue()
    vm.$loading({
      lock: true,
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
  }

  /**
   * 关闭正在加载页面
   */
  @Action
  public closeLoading() {
    const vm = new Vue()
    vm.$loading({
      lock: true,
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
    }).close()
    this.SET_LOADING(false)
  }

  /**
   * 获得团队信息
   */
  @Action
  public async getTeamListInfo() {
    const url = '/user/getTeamListInfo'
    const method = 'post'
    const config: AxiosRequestConfig = { url, method }
    const response = await request(config).catch((error) => {
      console.log(error)
      this.closeLoading()
    })
    if (response) {
      const { data } = response
      if (data && data.length > 0) {
        // 加入过团队，跳转至登录团队选择页面
        this.closeLoading()
        // --将回调地址放入当前用户属性中
        let redirectURI = window.location.href
        const index = redirectURI.indexOf('sso_service_ticket=')
        if (index > -1) {
          redirectURI = redirectURI.substring(0, index - 1)
        }
        this.SET_REDIRECTURL(redirectURI)
        window.location.href = `${window.location.origin}/#/chooseSupplier`
      } else {
        // 未加入任何团队，自动入驻异常回到首页
        this.closeLoading()
        window.location.href = `${window.location.origin}/#/home`
      }
    }
  }

  @Action
  public async getTeamList() {
    if (Number(Cookies.get('supplierId')) === -1) {
      // 未选择过登陆团队
      await this.getTeamListInfo()
    } else {
      this.closeLoading()
    }
  }

  /**
   * 接入iDaas登录
   */
  @Action
  public async BLogin(params?: params | string) {
    let gotoUrl = ''
    let openBlank = undefined
    if(typeof params === 'string') {
      gotoUrl = params
    } else if(typeof params === 'object') {
      gotoUrl = params.gotoUrl
      openBlank = params.openBlank
    }

    
      if(openBlank) {
        const returnUrl = window.location.href
        const blankUrl = `${window.env.glscDomain
        }/login.html?ReturnUrl=${encodeURIComponent(gotoUrl || returnUrl)}`

        window.open(blankUrl, '_blank')
      } else {
        const returnUrl = window.location.href
        window.location.href = `${window.env.glscDomain
        }/login.html?ReturnUrl=${encodeURIComponent(gotoUrl || returnUrl)}`
      }
    // const response = await extranetUser.getLogin().catch((error) => {
    //   console.log(`异常信息: ${error}`);
    //   Message.error("账户IDaas系统出现异常,请联系相关开发人员");
    //   throw new Error(error);
    // });
    // const { data } = response;
    // const { returnUrl } = data;
    // if (returnUrl) {
    //   const index = returnUrl.indexOf("&returnUrl");
    //   if (index > -1) {
    //     window.location.href = `${window.location.origin}`;
    //     if (gotoUrl) {
    //       window.location.href =
    //         returnUrl
    //           .substring(0, index)
    //           .concat("&source=WLY_SAAS")
    //           .concat(`&returnUrl=${encodeURIComponent(gotoUrl)}`) || "";
    //     } else {
    //       window.location.href =
    //         returnUrl
    //           .substring(0, index)
    //           .concat("&source=WLY_SAAS")
    //           .concat(
    //             `&returnUrl=${encodeURIComponent(window.location.href)}`
    //           ) || "";
    //     }
    //   }
    // }
    // return response;
  }

  /**
   * 内网自动化入驻
   */
  @Action
  public async saveDeveloper() {
    const url = '/user/saveDeveloper'
    const method = 'post'
    const config: AxiosRequestConfig = { url, method }
    const response = await request(config).catch((error) => {
      console.log(error)
      this.getTeamList()
    })
    if (response) {
      console.log(response)
      this.getTeamList()
    }
  }

  /**
   * 外网获取用户信息
   */
  @Action
  public async extranetGetUserInfo(gotoUrl: string = '') {
    if (!this.loginState) {
      this.SET_ACCOUNT_TYPE('glsc')
      const response = await extranetUser
        .getUserInfo()
        .catch(async (errorResponse) => {
          const { code, error, message } = errorResponse.data
          if (code === '401' && error === 'NotLogin') {
            this.closeLoading()
            this.SET_LOGINSTATE(false)
            await this.BLogin(gotoUrl)
            throw new Error('NotLogin')
          } else {
            message && Message.error(message)
            throw new Error()
          }
        })
      const { data } = response.data
      const { headImg, develop, name, userPin, supplierId, supplierCode, loginUsername } =
        data
      this.SET_HEADIMG(headImg)
      this.SET_USER_DEVELOP(develop)
      this.SET_NAME(name)
      this.SET_USER_PIN(userPin)
      this.SET_LOGINUSERNAME(loginUsername)
      this.SET_LOGINSTATE(true)
      Cookies.set('supplierId', supplierId)
      Cookies.set('groupCode', supplierCode)
      if (!this.user_pin) {
        Message.error(
          i18n.t('commonDevelopmentTools.apiTestTool.5pcpvrev9m83')
        )
      } else {
        // 外网自动化入驻
        if (Number(Cookies.get('supplierId')) === -1) {
          return await this.saveOutDeveloper()
        }
      }
      return response
    }
  }

  /**
   * 获取用户信息
   * @param type
   */
  @Action
  public async getUserInfo(params?: params | string) {
    let gotoUrl = ''
    if(typeof params === 'string') {
      gotoUrl = params
    } else if(typeof params === 'object') {
      gotoUrl = params.gotoUrl || ''
    }
    
    if (!this.loginState) {
      if (isIntranet()) {
        this.SET_ACCOUNT_TYPE('erp')
        const url = '/hrInfo/getUserInfo'
        const response = await request(url).catch((error) => {
          this.closeLoading()
          this.SET_LOGINSTATE(false)
          this.Login()
        })
        if (response) {
          const { data } = response
          const { headImg, develop, name, userPin, supplierId, supplierCode, loginUsername } =
            data
          this.SET_HEADIMG(headImg)
          this.SET_USER_DEVELOP(develop)
          this.SET_NAME(name)
          this.SET_USER_PIN(userPin)
          this.SET_LOGINSTATE(true)
          this.SET_LOGINUSERNAME(loginUsername)
          Cookies.set('supplierId', supplierId)
          Cookies.set('groupCode', supplierCode)
          if (this.user_pin === null) {
            Message.error(
              i18n.t('commonDevelopmentTools.apiTestTool.5pcpvrev9m83')
            )
          } else {
            if (this.ssoServiceTicket) {
              window.location.href = <string>(
                Utils.deleteSSOTicket(window.location.href)
              )
            }
            // 内网自动入驻
            return await this.saveDeveloper()
          }
        }
        return response
      } else {
        this.SET_ACCOUNT_TYPE('glsc')
        const url = '/hrInfo/getUserInfo'
        const response = await request(url).catch(async (error) => {
          this.closeLoading()
          this.SET_LOGINSTATE(false)
          await this.BLogin(params)
          return error
        })
        if (response) {
          const { data } = response
          const { headImg, develop, name, userPin, supplierId, supplierCode, loginUsername } =
            data
          this.SET_HEADIMG(headImg)
          this.SET_USER_DEVELOP(develop)
          this.SET_NAME(name)
          this.SET_USER_PIN(userPin)
          this.SET_LOGINSTATE(true)
          this.SET_LOGINUSERNAME(loginUsername)
          Cookies.set('supplierId', supplierId)
          Cookies.set('groupCode', supplierCode)
          if (!this.user_pin) {
            Message.error(
              i18n.t('commonDevelopmentTools.apiTestTool.5pcpvrev9m83')
            )
          } else {
            // 外网自动化入驻
            if (Number(Cookies.get('supplierId')) === -1) {
              return await this.saveOutDeveloper()
            }
          }
        }
        return response
      }
    }
  }

  /**
   * 外网登录操作
   */
  @Action
  public async extranetCheckLogin(gotoUrl: string = '') {
    // 设置32位随机UUID
    const lcpCookie = Cookies.get('lcpinfo')
    if (!lcpCookie) {
      const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
      const maxPos = chars.length
      let pwd = ''
      for (let i = 0; i < 32; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos))
      }
      Cookies.set('lcpinfo', pwd, { expires: 20000 })
    }
    this.SET_ACCOUNT_TYPE('glsc')
    if (this.loginState) {
      // 外网自动化入驻
      // if (Number(Cookies.get("supplierId")) === -1) {
      //   console.log("save out developer");
      return await this.saveOutDeveloper()
      // }
    }
    return await this.extranetGetUserInfo(gotoUrl)
  }

  /**
   * 登录操作
   */
  @Action
  public async CheckLogin(params?: params) {
    let { gotoUrl, openBlank } = params || {}
    if(typeof params === 'string') {
      gotoUrl = params
    }
    
    // 设置32位随机UUID
    const lcpCookie = Cookies.get('lcpinfo')
    if (!lcpCookie) {
      const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
      const maxPos = chars.length
      let pwd = ''
      for (let i = 0; i < 32; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos))
      }
      Cookies.set('lcpinfo', pwd, { expires: 20000 })
    }
    if (isIntranet()) {
      this.SET_ACCOUNT_TYPE('erp')
      if (!this.loginState) {
        this.SET_SSOSERVICETICKET(
          Utils.queryUrl(window.location.href).sso_service_ticket
        )
        if (this.ssoServiceTicket) {
          this.startLoading()
          const url = '/cookies/erp/set_token'
          const method = 'get'
          const ticket = this.ssoServiceTicket
          const domain = '.jdl.com'
          const params = { ticket, domain }
          const config: AxiosRequestConfig = { url, method, params }
          await request(config).catch((error) => {
            this.closeLoading()
            this.resetUser()
          })
        }
        this.getUserInfo() 
      }
    } else {
      this.SET_ACCOUNT_TYPE('glsc')
      if (this.loginState) {
        // 外网自动化入驻
        if (Number(Cookies.get('supplierId')) === -1) {
          console.log('save out developer')
          return await this.saveOutDeveloper()
        }
      } else {
        return await this.getUserInfo(params)
      }
    }
  }

  @Action
  public intranetCheckLogin() {
    this.CheckLogin()
  }

  /**
   * 接入iDaas退登
   */
  @Mutation
  public iDaasLogout(siteHost?: string) {
    const returnUrl = `${siteHost || window.location.origin}`
    const baseApi = '/lcp-rest'
    window.location.href = `//${siteHost || location.host
      }${baseApi}/logout?returnUrl=${encodeURIComponent(returnUrl)}`
  }

  /**
   * erp退出登录
   */
  @Action
  public async LogOut() {
    Cookies.remove('thor')
    Cookies.remove('lcpinfo')
    Cookies.remove('supplierId')
    Cookies.remove('groupCode')
    const site = window.localStorage.getItem('site')
    sessionStorage.clear()
    this.resetUser()
    if (isIntranet()) {
      this.SET_ACCOUNT_TYPE('erp')
      await this.cacheClear()
      window.location.href = `${window.location.origin}/#/home`
      const { domainErp } = window.env
      if (!domainErp) {
        return
      }
      window.location.href =
        `${domainErp}/sso/logout?ReturnUrl=${encodeURIComponent(
          window.location.href
        )}` || '' // erp内网 登出
    } else {
      this.SET_ACCOUNT_TYPE('glsc')
      // --需要退登Passport
      console.log('glsc logout')
      await jsonp('//sso.jd.com/exit', {}, () => { })
      this.iDaasLogout()
    }
  }
  @Action
  public async LogOutChangeSiteHost(siteHost: string) {
    if (this.currentSiteInfo.host === siteHost) {
      return
    }
    Cookies.remove('thor')
    Cookies.remove('lcpinfo')
    Cookies.remove('supplierId')
    Cookies.remove('groupCode')
    const site = window.localStorage.getItem('site')
    sessionStorage.clear()
    this.resetUser()
    if (isIntranet()) {
      this.SET_ACCOUNT_TYPE('erp')
      await this.cacheClear()
      window.location.href = `${siteHost}/#/home`
      // const currentSiteServerRoom = this.currentSiteInfo.serverRoom;
      // const domainErp = window.env.domainErpInfo[currentSiteServerRoom];
      const { domainErp } = window.env
      if (!domainErp) {
        return
      }
      window.location.href =
        `${domainErp}/sso/logout?ReturnUrl=${encodeURIComponent(
          `${siteHost}/#/home`
        )}` || '' // erp内网 登出
    } else {
      this.SET_ACCOUNT_TYPE('glsc')
      // --需要退登Passport
      console.log('glsc logout')
      await jsonp('//sso.jd.com/exit', {}, () => { })
      this.iDaasLogout(siteHost)
    }
  }

  /**
   * 根据pathname更新站点信息
   * 获取pathname取值逻辑
   * 1. 通过window.location.pathname获取第一级地址作为站点信息
   * 2. 如果第一步获取为空，则降级，读取location中的site信息
   * 3. 如果第二步获取也为空，则继续降级，默认设置为美国站点，同时更新站点信息以及页面重定向
   * 4. 当pathname为国际客户端控制台时，默认读取location中站点信息
  */
  @Action
  public UPDATE_SITE_FROM_PATHNAME() {
    // 获取当前URL的pathname
    const { pathname } = window.location
    // 定义匹配第一级路径的正则表达式
    const regex = /^\/(us|eu|intl-admin)(\/|$)/
    // 执行匹配
    const match = pathname.match(regex) as Array<string> | null
    let site = match ? match[1] : ''
    if (!site) {
      const localSite = localStorage.getItem('site')
      // Look:
      site = (
        window.location.hostname.indexOf('hl.lcp.jdl.com') 
        || 
        window.location.hostname.indexOf('hl1.lcp.jdl.com')
      ) > -1 ? 'eu' : (localSite || 'us')
      window.location.pathname = `/${site}${pathname}` // 默认跳转美国站点
      // this.CHANGE_SITE(localSite || 'us') // 默认跳转美国站点
    }
    if (site === this.consoleSite) {
      const localSite = localStorage.getItem('site')
      site = localSite || 'us'
    }
    this.SET_SITE(site)
  }
  @Action CHANGE_SITE(site: string) {
    const newPathname = `/${site}/`
    // window.location.pathname = newPathname
    const { origin } = window.location
    window.location.href = `${origin}${newPathname}#/home`
  }
}

const UserModule: any = getModule(User)
export default UserModule
