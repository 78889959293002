export default {
  needI18n: false,
  extranetHost: 'dev-test-cloud.jdl.com',
  intranetHost: 'dev-test-lcp.jdl.com',
  baseEnv: 'lEnv',
  buildEnv: 'local',
  baseStreamAccount: '',
  baseStreamCloudAccount: '',
  idaasLogoutDomain: '//test-soms.jdl.com',
  domainErp: '//test.ssa.jd.com',
  gatewayAuthorization: '//test.lop-oauth.jd.com',
  gatewayBaseApi: '//proxy.jdl.com/',
  gatewayFileBaseApi: '//proxy.jd.com/',
  gatewayOauthBaseApi: '//test-api.jdl.com',
  gatewayUploadLopDn: 'lcp.cloud.sdkfile.com', // test.sdkfile.com
  gatewayPtnr: '{"appid":"lop_erp"}',
  gatewayPassportPtnr: '{"appid":"lop_passport"}',
  gatewayPkId: '406',
  gatewayPk: 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCFa1b+cyzLIVLaxqOZoH/JJscPWjKfknQIonWY5/XTSwbVVFapwQg52aTzJ0JIiuipZ92Dx8UnMfY62QpoDiBypxx5dbCc6YFL+0XZASW2o+7fmHr/4DHMSkAfVJ5u+h4TLtHhxdKrXgWMog2nQ9oRDwFpiRGMSsLNeJut7lY+YwIDAQAB',
  apiQuickPath: '/open-business-document/api-doc/104/110',
  docHow2Order: '/devSupport/151615',
  docHow2OrderInner: '/devSupport/8',
  docSdk: 'devSupport/81',
  softwareLicense: '/devSupport/151',
  sdkUpdateVersion: '//lcp.jdl.com/#/apiNewHand/53036',
  idaasChangePassword: '//test-soms.jdl.com/static/findPwd.html?orgId=1&source=WLY_SAAS&loginType=superAdmin&returnUrl=',
  gatewayApiDecoupling: 'http://test-api.jdl.com',
  gatewayApiDecouplingLop: 'LOP_OPEN_APIDEPOT',
  gatewayApiDecouplingOpenBiz: 'http://test-api.jdl.com',
  gatewayApiDecouplingLopOpenBiz: 'DEV_LOP_OPEN_APIDEPOT_OUTER',
  developmentDocumentationId: 53209
}
