import extranetUser from "@/api/extranet/user";
import { UserModule } from '@/store'

const glscLogin = async () => {
  const response = await extranetUser.getUserHrInfo().catch(() => { });
  if (response) {
    await UserModule.CheckLogin();
  }

}
export {
  glscLogin,
}
