import nationalStore from './national'
import nationalStoreUserModule from './national/modules/user'
import nationalStoreSappModule from './national/modules/app'
import internationalStore from './international'
import internationalStoreUserModule from './international/modules/user'
import internationalStoreSappModule from './international/modules/app'

import isInternational from '@/utils/isInternational'
const store = isInternational() ? internationalStore : nationalStore;
const UserModule = isInternational() ? internationalStoreUserModule : nationalStoreUserModule;
const SappModule = isInternational() ? internationalStoreSappModule : nationalStoreSappModule;

export default store
export {
  UserModule, 
  SappModule
}
