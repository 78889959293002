export default {
  needI18n: false,
  extranetHost: 'uat-cloud.jdl.com',
  intranetHost: 'uat-lcp.jdl.com',
  baseEnv: 'uat',
  buildEnv: 'uat',
  baseStreamAccount: 'JA2020_1222753',
  baseStreamCloudAccount: 'JA2020_1222753',
  idaasLogoutDomain: 'https://pre-account.jdl.com',
  domainErp: '//ssa.jd.com',
  gatewayAuthorization: 'https://uat-oauth.jdl.com',
  gatewayBaseApi: 'https://uat-api.jdl.com/',
  gatewayFileBaseApi: 'https://uat-api.jdl.com/',
  gatewayOauthBaseApi: 'https://uat-proxy.jd.com',
  // "gatewayUploadLopDn": "lop.cloud.sdkfile.com",
  gatewayUploadLopDn: 'lcp.cloud.sdkfile.com',
  gatewayPtnr: '{"appid":"lop_erp"}',
  gatewayPassportPtnr: '{"appid":"lop_passport"}',
  apiQuickPath: '/open-business-document/api-doc/154/183',
  docHow2Order: '/devSupport/2',
  docHow2OrderInner: '/devSupport/32',
  docSdk: 'devSupport/6',
  softwareLicense: '/devSupport/52761',
  sdkUpdateVersion: 'https://cloud.jdl.com/#/devSupport/53074',
  idaasChangePassword: 'https://pre-account.jdl.com/static/findPwd.html?orgId=1&source=WLY_SAAS&loginType=superAdmin&returnUrl=',
  gatewayApiDecoupling: 'http://uat-lop.jdl.com',
  gatewayApiDecouplingLop: 'UAT_LOP_OPEN_APIDEPOT',
  gatewayApiDecouplingOpenBiz: 'https://uat-api.jdl.com',
  gatewayApiDecouplingLopOpenBiz: 'UAT_LOP_OPEN_APIDEPOT_OUTER',
  developmentDocumentationId: 53209
}
