
import { AxiosRequestConfig } from 'axios'
export interface ApiTest {
  [key: string]: any;
}
interface RequestMsg {
  post: (url: string, data: any, config?: AxiosAxiosRequestConfig) => Promise<any>;
}

type AxiosAxiosRequestConfig = AxiosRequestConfig & {
  noMsg?: boolean | null;
  defaultTime?: number | null;
}



import request_msg  from "@/utils/request_msg";
// 仅绕过TS校验 增加字段信息
const request_msg_nomsg: RequestMsg = request_msg
const apiTest = {
  /**
   * 从网关获取格式化数据
   *
   * @param {array} params 可见参数数组
   */
  getFormatDataForGateway: (data: any, noMsg: any, defaultTime = 30 * 1000) =>
    request_msg_nomsg.post("/apiTest/getFormatDataForGateway", data, { noMsg, defaultTime }),

  /**
   * 获取格式化JSON数据
   *
   * @param {number} apiId
   * @param {string} jsonString json
   */
  getFormatData: (data: any) =>
    request_msg.post("/apiTest/getFormatData", data),
};

export default apiTest;
