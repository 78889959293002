export default {
  extranetHost: 'open.cloud.jdl.com',
  buildEnv: 'prod',
  baseEnv: 'prod',
  baseStreamAccount: 'JA2020_1222750',
  baseStreamCloudAccount: 'JA2020_1233042',
  glscDomain: 'https://pulsar-login.jdl.com',
  gatewayUploadLopDn: '',
  gatewayUploadLopDnXml: 'xml.upload.jdl.com',
  gatewayPtnr: '{"appid":"lop_erp"}',
  gatewayPartnerMessageXmlBucket: '',
  gatewayPartnerMessageLopDn: '',
  docDescription: '/apiNewHand/10',
  docApiPublish: '/apiNewHand/8',
  docApiTest: 'devSupport/15',
  docSdkCreate: 'devSupport/16',
  docMessageListTag: 'devSupport/52784',
  idaasModifyPhone: 'https://account.jdl.com/static/modifyPhone.html?source=&tc=&returnUrl=',
  gatewayApiDecouplingLop: 'lop.open.apidepot',
  gatewayLogDecouplingLop: ''
}
