import uat from './env.uat'
import prod from './env.prod'
import configEnvMap from '../config'

let config = {}
if (
  configEnvMap[window.location.hostname] && 
  configEnvMap[window.location.hostname].env === 'uat'
) {
  config = uat
} else {
  config = prod
}

export default config
