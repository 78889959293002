<template>
  <!-- 内网首页顶栏 -->
  <div class="top-bar">
    <tips class="tips" @clsoeNotice="clsoeNotice" v-if="showNotice"/>
    <div :class="activeMenuBar ? `${className} navbar-hover` : className" ref="topBar">
      <div class="navbar-wrap">
        <div class="navbar-logo-wrap" @click="forcePush('/')">
          <div class="logo-wrap">
            <router-link
              to="/"
              :clstag="clstag('Management_1702468662190|1', '')"
            ></router-link>
          </div>
        </div>
        <div class="menu-list">
            <div class="navbar-bm">
              <!-- menu-trigger="click" -->
              <el-menu
                :default-active="activeIndex"
                class="navbar-menu"
                mode="horizontal"

                @select="handleSelect"
              >
                <el-menu-item index="/" @click="forcePush('/')">
                  <router-link
                    to="/"
                    class="router-link"
                    :clstag="clstag('Management_1702468662190|1', '')"
                    >首页</router-link
                  >
                </el-menu-item>
                <el-menu-item v-if="!isInternational" index="/customer" @click="forcePush('/customer')">
                  <router-link
                    to="/customer"
                    class="router-link"
                    :clstag="
                      clstag('Management_1702468662190|2', '')
                    "
                    >客户动态</router-link
                  >
                </el-menu-item>
                <el-menu-item index="/document" @click="forcePush('/document')">
                  <router-link
                    to="/document"
                    class="router-link"
                    :clstag="clstag('Management_1702468662190|3', '')"
                    >文档中心</router-link
                  >
                </el-menu-item>
                <el-submenu
                  index="2"
                  popper-class="custom-popover-menu"
                  class="esp-expand-menu"
                  @mouseenter.native="handelOpen()"
                  @mouseleave.native="menuHoverClose()"
                  >
                  <template slot="title">帮助与支持</template>
                  <div class="expand-menu" @mouseenter="handelOpen()" @mouseleave="handelClose()">
                    <div class="expand-menu-card"  >
                      <el-card class="box-card" shadow="never" v-for="card in helpList" :key="card.type">
                        <div slot="header" class="clearfix">
                          <span>{{ card.type }}</span>
                        </div>
                        <div
                        v-for="item in card.items"
                        :key="item.title"
                        :class="item.disabled ? 'text item disabled' : 'text item'"
                        @click="getDetails(item.link)"
                        :clstag="clstag(item.clstag, '')"
                        >
                          {{item.title}}
                        </div>
                      </el-card>
                    </div>
                  </div>
                </el-submenu>

                <el-submenu
                  index="3"
                  popper-class="custom-popover-menu"
                  class="esp-expand-menu"
                  @mouseenter.native="handelOpen()"
                  @mouseleave.native="menuHoverClose()"
                  >
                  <template slot="title">了解开放平台</template>
                  <div class="expand-menu" @mouseenter="handelOpen()" @mouseleave="handelClose()">
                    <div class="expand-menu-card"  >
                      <el-card class="box-card" shadow="never" v-for="card in knowledgeList" :key="card.type">
                        <div slot="header" class="clearfix">
                          <span>{{ card.type }}</span>
                        </div>
                        <div
                        v-for="item in card.items"
                        :key="item.title"
                        :class="item.disabled ? 'text item disabled' : 'text item'"
                        @click="getDetails(item.link)"
                        :clstag="clstag(item.clstag, '')"
                        >
                          {{item.title}}
                        </div>
                      </el-card>
                    </div>
                  </div>
                </el-submenu>

              </el-menu>
            </div>
        </div>
        <div class="actions-list">
          <!-- <el-input v-model="searchContent" class="actions-list-search" placeholder="请输入内容"></el-input> -->
           <!-- here -->
            <switchSite v-if="isInternational"></switchSite>
          <el-button
            type="primary"
            class="actions-list-btn"
            @click="gotoDashBord"
            :clstag="clstag('Management_1702468662190|40', '')"
            >控制台</el-button>
          <el-dropdown class="actions-list-user" v-if="name">
            <div class="avatar-wrapper">
              <img :src="headImg" class="user-avatar" />
              <span class="user-name">{{ name }}</span>
              <i class="el-icon-arrow-down" />
            </div>
            <el-dropdown-menu slot="dropdown" class="actions-list-user-dropdown">
              <el-dropdown-item @click.native="changeTeam">
                <el-button class="user-dropdown-button" :clstag="clstag('Management_1702468662190|41', '')">
                  <span>切换团队</span>
                  <span class="icon"></span>
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item @click.native="thisLogout">
                <el-button class="user-dropdown-button" :clstag="clstag('Management_1702468662190|42', '')">退出登录</el-button>
              </el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <span v-else class="actions-list-default" @click="onClickLogInButton">
            ERP登录
          </span>
        </div>
      </div>
    </div>
    <el-dialog
      title="选择团队"
      :visible="teamDialogVisible"
      width="460px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <div class="team-select">
        <el-select
          v-model="teamInfo"
          value-key="id"
          placeholder="团队名称"
          size="big"
          style="width:100%"
        >
          <el-option
            v-for="item in teamList"
            :key="item.id"
            :label="item.name"
            :value="item"
          ></el-option>
        </el-select>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          @click="chooseSupplier"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import tips from './tips.vue'
import switchSite from './switchSite'
import isInternational from '@/utils/isInternational'

import { UserModule, SappModule } from '@/store'
import request from '@/utils/request'
import pageClick from '@/utils/pageClick'
import avatar from '@/assets/front/avatar.png'
import { helpList, knowledgeList } from './data'

export default {
  components: {
    tips,
    switchSite,
  },
  data() {
    return {
      searchContent: '',
      isShowInp: true,
      activeIndex: '/',
      searchResult: [],
      state: '',
      teamDialogVisible: false,
      teamInfo: undefined,
      teamList: [],
      showNotice: true, // 从localStore获取
      helpList,
      knowledgeList,
      className: 'navbar',
      activeMenuBar: false,
      isInternational: isInternational()
    }
  },
  computed: {

    computeIsShow() {
      const path = `/${this.$route.path.split('/')[1]}`
      const routerTable = [
        '/newHand',
        '/apiNewHand',
        '/qs',
        '/docSoftwareSystem',
        '/devSupport',
      ]
      return routerTable.includes(path)
    },
    isFixed() {
      return SappModule.isNavbarFixed
    },
    classObj() {
      return {
        isFixed: SappModule.isNavbarFixed,
      }
    },

    hasLogIn() {
      return UserModule.loginState
    },

    headImg() {
      return UserModule.headImg || avatar
    },
    name() {
      return UserModule.name
    },
    user_develop() {
      return UserModule.user_develop
    },
    routePath() {
      console.log(`computed:${this.$route.path}`)
      // if (this.$route.path === '/searchResult') {
      //   this.isShowInp = false
      // } else {
      //   this.isShowInp = true
      // }
      if (this.$route.path === '/home') {
        this.activeIndex = '/'
      } else if (
        this.$route.path.indexOf('/apiMethodList') !== -1 ||
        this.$route.path.indexOf('/apiMethodDocument') !== -1 ||
        this.$route.path.indexOf('/apiTestMainPage') !== -1 ||
        this.$route.path.indexOf('/document') !== -1
      ) {
        this.activeIndex = '/document'
      } else if (
        this.$route.path.indexOf('/newHand') !== -1 ||
        this.$route.path.indexOf('/devSupport') !== -1 ||
        this.$route.path.indexOf('/question') !== -1 ||
        this.$route.path.indexOf('/apiNewHand') !== -1 ||
        this.$route.path.indexOf('/docSoftwareSystem') !== -1
      ) {
        this.activeIndex = '/helpDocument'
      } else if (
        this.$route.path.indexOf('/productDetail') !== -1 ||
        this.$route.path.indexOf('/apply') !== -1 ||
        this.$route.path.indexOf('/product') !== -1
      ) {
        this.activeIndex = '/product'
      } else {
        this.activeIndex = this.$route.path
      }
    },
  },
  watch: {
    routePath(newValue, oldValue) {
      // if (this.$route.path === '/searchResult') {
      //   this.isShowInp = false
      // } else {
      //   this.isShowInp = true
      // }
      if (this.$route.path === '/home') {
        this.activeIndex = '/'
      } else if (
        this.$route.path.indexOf('/apiMethodList') !== -1 ||
        this.$route.path.indexOf('/apiMethodDocument') !== -1 ||
        this.$route.path.indexOf('/apiTestMainPage') !== -1 ||
        this.$route.path.indexOf('/document') !== -1
      ) {
        this.activeIndex = '/document'
      } else if (
        this.$route.path.indexOf('/newHand') !== -1 ||
        this.$route.path.indexOf('/devSupport') !== -1 ||
        this.$route.path.indexOf('/question') !== -1 ||
        this.$route.path.indexOf('/apiNewHand') !== -1 ||
        this.$route.path.indexOf('/docSoftwareSystem') !== -1
      ) {
        this.activeIndex = '/helpDocument'
      } else if (
        this.$route.path.indexOf('/productDetail') !== -1 ||
        this.$route.path.indexOf('/apply') !== -1 ||
        this.$route.path.indexOf('/product') !== -1
      ) {
        this.activeIndex = '/product'
      } else {
        this.activeIndex = this.$route.path
      }
    },
  },
  beforeMount() {
    this.showNotice = !(localStorage.getItem('showNotice') === 'false') && !this.isInternational
    console.log(' this.showNotice', this.showNotice)
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    // if (this.$route.name === 'customer') {
    //   this.className = 'fixed-position-with-opacity navbar'
    // } else {

    // }
  },
  methods: {
    async menuHoverClose() {
      await new Promise(resolve => setTimeout(resolve, 200))
      this.$nextTick(() => {
        this.activeMenuBar = false
      })
    },
    async handelOpen() {
      await new Promise(resolve => setTimeout(resolve, 200))
      this.activeMenuBar = true
    },
    async handelClose() {
      await new Promise(resolve => setTimeout(resolve, 200))
      this.$nextTick(() => {
        this.activeMenuBar = false
      })
    },
    forcePush(path) {
      this.$router.push({ path }).catch((err) => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
    },
    handleScroll() {
      // 首先修改相对滚动位置
      this.scrollTop = window.pageYOffset || document.body.scrollTop
      this.$nextTick(() => {
        this.className = this.getAbsolutePosition()
      })
    },
    getAbsolutePosition() {
      let result = 'navbar'

      if (this.scrollTop > 96) {
        result = 'fixed-position-with-opacity navbar'
      } else if (this.showNotice && this.scrollTop <= 32) {
        result = 'fixed-position-with-top navbar'
        this.$refs.topBar.style.top = `${(32 - this.scrollTop) || 0}px`
      } else if (this.showNotice && this.scrollTop > 32) {
        this.$refs.topBar.style.top = 0
        result = 'fixed-position-without-top navbar'
      } else if (!this.showNotice && this.scrollTop <= 64) {
        this.$refs.topBar.style.top = 0
        result = 'fixed-position-without-top navbar'
      } else if (!this.showNotice && this.scrollTop > 64) {
        this.$refs.topBar.style.top = 0
        result = 'fixed-position-with-opacity navbar'
      }
      if (this.$route.name !== 'document' && this.$route.name !== 'Home') {
        result += ' white-bg'
      }
      console.log('this.$route.name', this.$route.name)
      return result
    },
    getDetails(link) {
      if (link) {
        window.open(link, '_blank')
      }
    },
    clsoeNotice() {
      this.showNotice = false
      localStorage.setItem('showNotice', false)
      this.getAbsolutePosition()
    },
    // 点击事件埋点
    clstag(intranetId, extranetId) {
      return pageClick(intranetId, extranetId)
    },
    handleSelect(key, keyPath) {
      window.addEventListener('scroll', this.handleScroll)
      this.handleScroll()
      // console.log(key, keyPath)
      // if (key === '/customer') {
      //   this.$nextTick(() => {
      //     this.className = 'fixed-position-with-opacity navbar'
      //   })
      // } else {
      //   window.addEventListener('scroll', this.handleScroll)
      //   this.handleScroll()
      // }
    },
    thisLogout() {
      UserModule.intranetLogout()
    },
    async onClickLogInButton() {
      await UserModule.intranetCheckLogin().catch((error) => {
        throw new Error(`onClickLogInButton, ${error}`)
      })
    },
    async onClickConsoleButton() {
      let redirectNextLink = `http://${window.location.host}/admin/`
      const index = redirectNextLink.indexOf('sso_service_ticket=')
      if (index > -1) {
        redirectNextLink = redirectNextLink.substring(0, index - 1)
      }
      sessionStorage.setItem('redirectNextLink', redirectNextLink)
      await UserModule.intranetCheckLogin().catch((error) => {
        throw new Error(`onClickConsoleButton, ${error}`)
      })
    },
    async onClickMySystemButton() {
      let redirectNextLink = `http://${window.location.host}/#/mySystem`
      const index = redirectNextLink.indexOf('sso_service_ticket=')
      if (index > -1) {
        redirectNextLink = redirectNextLink.substring(0, index - 1)
      }
      sessionStorage.setItem('redirectNextLink', redirectNextLink)
      await UserModule.intranetCheckLogin().catch((error) => {
        throw new Error(`onClickMySystemButton, ${error}`)
      })
    },

    gotoDashBord() {
      const isDevelop = UserModule.user_develop
      console.log(UserModule.headImg)
      console.log(`用户Pin: ${UserModule.user_pin}`)
      console.log(`用户名: ${UserModule.name}`)
      console.log('用户类型: erp')
      console.log(`开发者身份: ${isDevelop}`)
      // isDevelop='no'
      if (isDevelop === 'yes') {
        UserModule.gotoAdmin()
      } else {
        // --如果不是开发者,判断用户类型
        // 2020/12/18 feat:选择了团队才能绑定开发者身份信息，内网develop==='no',判断是否有团队信息，
        console.log('develop===no')
        this.handleGetTeamListInfo()
      }
    },

    handleGetTeamListInfo() {
      request({
        url: '/user/getTeamListInfo',
        method: 'post',
      })
        .then((rsp) => {
          if (rsp.data.length !== 0) {
            // 有团队信息，->选择团队
            console.log('有团队信息，->选择团队')
            window.location.href = `${window.location.origin}/#/chooseSupplier`
          } else {
            // 无团队信息->回到首页
            console.log('无团队信息->回到首页')
            window.location.href = `${window.location.origin}/#/home`
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },

    search(event) {
      console.log('search')
      if (!this.state) {
        return
      }
      const q = this.state
      this.$router.push({ path: '/searchResult', query: { q } })
      this.state = ''
      event.target.blur()
    },

    changeTeam() {
      this.teamDialogVisible = true
      this.getTeamListInfo()
    },

    async getTeamListInfo() {
      try {
        const res = await request({ url: '/user/getTeamListInfo', method: 'post', })
        this.teamList = res.data
        const supplierId = Cookies.get('supplierId')
        this.teamInfo = this.teamList.find(team => team.id == supplierId)
      } catch (error) {
        console.log(error)
      }
    },

    handleClose() {
      this.teamDialogVisible = false
    },

    chooseSupplier() {
      Cookies.set('supplierId', this.teamInfo.id)
      Cookies.set('groupCode', this.teamInfo.code)
      this.$message({
        type: 'success',
        message: '切换团队成功!',
      })

      this.teamDialogVisible = false
      this.$router.go(0)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";
.white-bg {
  background-color: #FFFFFF !important;
}
.top-bar {
  max-height: 96px;
}
.disabled-fix {
  position: static !important;
}
.fixed-position-with-opacity {
  top: 0;
  background-color: #FFFFFF !important;
  position: fixed;
}
.fixed-position-with-top {
  position: fixed;
}
.fixed-position-without-top {
  top: 0 !important;
  position: fixed;
}
.navbar {
  min-width: 1366px;
  width: 100%;
  height: 64px;

  background-color: rgba(255,255,255,.1);
  position: fixed;
  z-index: 99;

  box-sizing: border-box;
  padding: 0 43px;

  display: grid;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #FFFFFF !important;
  }

}
.navbar-hover {
  background-color: #FFFFFF !important;
}
.navbar-wrap {
  width: 1280px;
  min-width: 1280px;
  height: 100%;
  text-wrap: nowrap;
  display: grid;
  grid-template-columns: 337px 530px 413px;
  .navbar-logo-wrap {
    width: 321px;
    height: 64px;
    vertical-align: top;
    display: inline-block;
    text-align: left;
    cursor: pointer;
    .logo-wrap {
      // margin: 20px  16px 20px 0;
      margin-left: 12px;
      width: 300px;
      height: 100%;
      background: url("~@/assets/logo/logo-client-inter.png");
      background-size: cover;
      display: inline-block;
    }
  }
  .esp-expand-menu {
    /deep/.el-submenu__title {
      border-bottom: 0px;
      height: 64px;
      line-height: 64px;
      &:hover {
        font-weight: 500;
      }
    }
  }
  .actions-list {
    // width: 474px;
    // height: 40px;
    text-align: right;
    display: inline-block;
    line-height: 64px;
    vertical-align: top;
    .actions-list-search {
      width: 200px;
      vertical-align: top;
      border-radius: 8px;
      margin-right: 13px;
    }
    .actions-list-btn {
      width: 74px;
      height: 32px;
      margin: 16px 0;
      vertical-align: top;
      background: #3C6EF0;
      border-radius: 8px;
    }
    .actions-list-user {
      width: 100px;
      // height: 100%;
      vertical-align: top;
      text-wrap: nowrap;
      margin: 12px 0;
      margin-left: 16px;
      display: inline-block;
      // background-color: #c8f1c2;
      .avatar-wrapper {
        line-height: 40px;
        color: #23252B;
        cursor: pointer;
        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 100px;
        }

        .user-name {
          display: inline-block;
          padding: 0 8px;
          color: #23252B;
        }

        .el-icon-arrow-down {
          position: absolute;
          right: -16px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 16px;
          margin-left: 16px;
          // color: #f0f0f0;
        }
      }
    }
    .actions-list-default {
      list-style: 20px;
      font-size: 14px;
      font-family: "PingFang SC", "Microsoft YaHei", "黑体",
    "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
      color: #23252B;
      margin-left: 16px;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss">
.menu-list {
    // width: 512px;
    text-align: center;
    height: 100%;
    vertical-align: top;
    display: inline-block;
    .navbar-bm {
      height: 64px;
      .navbar-menu {
        border-bottom: none;
        background-color: transparent;
      }
      .el-submenu {
        border-bottom: none;
        min-width: 88px;
        max-width: 125px;

        .el-submenu__title {
          color: #525765 !important;
          font-family: "PingFang SC", "Microsoft YaHei", "黑体",
          "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
          &:hover {
            background-color: #FFFFFF;
          }
        }
      }
      .el-menu-item {
        border-bottom: none;
        min-width: 88px;
        max-width: 120px;
        line-height: 64px;
        .router-link {
          color: #525765;
          font-family: "PingFang SC", "Microsoft YaHei", "黑体",
          "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
        }
        &:hover {
          font-weight: 500;
          background-color: #FFFFFF;
        }
        &:focus {
          background-color: transparent;
        }
        a {
          vertical-align: top;
        }
      }
      .is-active {

        font-weight: 500;
        .router-link-active {
          color: #3C6EF0;
        }
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 16px;
          height: 4px;
          border-radius: 2px;
          transform: translateX(-8px);
          background-color: #3C6EF0;
        }
      }
    }
  }
.custom-popover-menu {
  transition: width 0s,height .2s,transform .3s;
  width: 100%;
  left: 0;
  // text-align: center;
  .el-menu--popup-bottom-start {
    margin-top: 0px;
    padding-top: 0px;
    .expand-menu {
      padding-top: 5px;
      margin: 0 calc((100% - 1080px) / 2);
      width: 1080px;
      .expand-menu-card {
        display: grid;
        // grid-column: 1;
        grid-template-columns: repeat(5,1fr);
        margin-left: -70px;
        .box-card {
          display: inline;
          min-width: 160px;
          margin-left: 70px;
          width: max-content;
          .el-card__header {
            padding-left: 0;
            padding-bottom: 12px;
            border-bottom: 2px solid;
            border-image: linear-gradient(270deg, rgba(214,204,255,.5) 0%,rgba(182,207,255,.5) 100%);
            border-image-slice: 2;
            .clearfix {
              font-family: "JDLANGZHENGTI--GB1", "PingFang SC", "Microsoft YaHei", "黑体",
                            "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
            }
          }
          .el-card__body {
            padding: 20px 0;
            .item {
              color: #525765;
              padding-bottom: 12px;
              cursor: pointer;
              &:hover {
                font-weight: 500;
                color: #3C6EF0;
              }
            }
            .disabled {
              cursor: not-allowed;
              color: #AFB6C9;
              &:hover {
                font-weight: normal;
                color: #AFB6C9;
              }
            }
          }
        }

      }
    }
  }
}
.actions-list-user-dropdown {
  box-sizing: border-box;
  padding: 12px 8px;
  .el-dropdown-menu__item {
    padding: 0;
    .user-dropdown-button {
      width: 124px;
      height: 40px;
      border: none;
      color: #525765;
      text-align: left;
      &:hover {
        background-color: #F5F5F6;
      }
      .icon {
        width: 16px;
        height: 16px;
        display: inline-block;
        vertical-align: bottom;
        margin-left: 20px;
        background-size: cover;
        background-image: url('~@/assets/front/Lui-icon-back.png');
      }
    }
    &:hover {
      background-color: transparent;
    }
  }
  .popper__arrow {
    display: none;
  }
}
</style>
