import request from "@/utils/request";

const competenceCenter = {
    /**
     * 查询能力中心标签分类
     */
    findClassifiedBizUnitList: (data: object) => {
        let query = ''
        if (data) {
            query = `?solutionType=${(data as any).solutionType}`
        }
        return request.post("/bizunit/findClassifiedBizUnitList" + query)
    }
};

export default competenceCenter;