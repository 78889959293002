import axios from 'axios'
import { Message } from '@lui/lui-ui'
import Cookies from 'js-cookie'

import isIntranet from '@/utils/isIntranet'
import { reLogin } from '@/utils/oauth'
import AccountType from './getAccountType'
import isInternational from './isInternational'
import { UserModule } from '@/store';

const service = axios.create({
  baseURL: '/lcp-rest',
  timeout: 10000,
  withCredentials: true,
})

service.interceptors.request.use(
  (config) => {
    config.headers['Account-Type'] = AccountType()
    // request请求头加上supplierId
    config.headers.supplierId = Number(Cookies.get('supplierId')) || -1
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    if (isInternational()) {
      config.headers["site"] = UserModule.site;
      config.headers["locale"] = UserModule.locale;
    }

    // 允许接口差异化定制超时时间
    // if (config.headers.timeout) {
    //   service.defaults.timeout = config.headers.timeout
    // } else {
    //   service.defaults.timeout = 10000
    // }

    return config
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    const { data, config } = response
    const urlWhiteList = ['/evaluation/docEvaluation']

    if (data.code === 20000) {
      return data
    }
    if (!isIntranet() && config.url && urlWhiteList.includes(config.url)) {
      Message({
        message: '操作过于频繁，请稍后再试',
        type: 'warning',
      })
      return data
    }

    if (data.code === '999999' && data.error === 'NotLogin') {
      // 外网登录态失效
      if (config.url !== '/hrInfo/getUserInfo') {
        reLogin()
        return Promise.reject(new Error())
      }
      return Promise.reject(new Error())
    }
    if (config.url !== '/hrInfo/getUserInfo') {
      Message({
        message: data.message || 'Error',
        type: 'error',
        duration: 5 * 1000,
      })

    }
    return Promise.reject(new Error(data.message || 'Error'))
  },
  (error) => {
    if (error.response) {
      // 发出的请求收到了response，但非2XX状态码
      if (error.response.status === 520 || error.response.status === 401) {
        if (error.config.url !== '/hrInfo/getUserInfo') {
          reLogin()
        }
        return Promise.reject(new Error())
      }
    } else if (error.request) {
      // 请求发出，但没有收到response
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
      console.log(error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      // 设置触发错误的请求时，message信息
      console.log('Error', error.message)
    }
    console.log(error.config)

    // 请求超时处理
    if (error.message.includes('timeout')) {
      error.message = '请求超时，请稍后重试'
    }
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000,
    })
    return Promise.reject(error)
  }
)
export default service
