import bizapinterface, { Bizpinterface } from './bizapinterface'
import bizunit, { Bizunit } from './bizunit'
import apiTest, { ApiTest } from './apiTest'
import hrInfo, { HrInfo } from './hrInfo'
import bizmsg, { Bizmsg } from './bizmsg'
import bizview, { Bizview } from './bizview'
import workOrder, { WorkOrder } from './workOrder'
import evaluate, { Evaluate } from './evaluate'
import autobots, { AutoBots } from "./autoBots";

export interface Lcp {
  bizapinterface: Bizpinterface;
  bizunit: Bizunit;
  apiTest: ApiTest;
  hrInfo: HrInfo;
  bizmsg: Bizmsg;
  bizview: Bizview;
  workOrder: WorkOrder;
  evaluate: Evaluate;
  autobots: AutoBots;
}

// 外网平台统一注册API
const lcp = {
  bizapinterface,
  bizunit,
  apiTest,
  hrInfo,
  bizmsg,
  bizview,
  workOrder,
  evaluate,
  autobots,
}

export default lcp
