<template>
  <div :class="isZoom
    ? 'auto-bots-container'
    : 'auto-bots-container auto-bots-container_Zoom'
    "
    v-loading="initHistory"
    >
    <div class="auto-bots-main">
      <div class="auto-bots_header">
        <div class="header_title">
          <img src="@/assets/img/headertitle.png" class="robotImg" />
          <span>物流开放平台智能助手</span>
        </div>
        <div v-if="isZoom" class="header_zoom" @click="handleZoom">
          <img src="@/assets/img/zoom.png" class="img_zoom" />
        </div>
        <div v-else class="header_zoom" @click="handleZoom">
          <img src="@/assets/img/zoomSmall.png" class="img_zoom" />
        </div>
        <div class="header_close" @click="closeRobot">
          <img src="@/assets/img/close.png" class="im_btn_close" />
        </div>
      </div>
      <div class="auto-bots_container_content" ref="scrollContainer">
        <div class="auto-bots_container">
        <div class="auto-bots_container_header_name">
          <p>物流开放平台智能助手 {{ sendTime }}</p>
        </div>
        <div class="auto-bots_container_header_title">
          <img class="auto-bots_container_header_title_img" src="@/assets/img/robot.png" alt="标题头像" />
          <div style="position: relative">
            <span class="auto-bots_container_header_title_span">
              Hi~猜你想问以下问题，或者直接向我提问吧～
            </span>
          </div>
        </div>
        <div class="auto-bots_container_recommend">
          <div class="auto-bots_container_recommend_div" v-for="(item,index) in relQuestions" :key="index" @click="handleRequest('ruleForm', item)">
            <p>{{ item }}</p>
          </div>
          <!-- <div class="auto-bots_container_recommend_div">
            1. 咨询智能助手获取自动回复
          </div>
          <div class="auto-bots_container_recommend_div">
            2. 提报
            <a style="color: rgba(60, 110, 240, 1)" type="primary" target="_blank"
              href="https://cloud.jdl.com/admin/#/upgrade/work-order/new">工单</a>获取人工支持
          </div>
          <div class="auto-bots_container_recommend_div">
            3. 全局<a style="color: rgba(60, 110, 240, 1)" type="primary" target="_blank"
              href="https://cloud.jdl.com/#/searchResult?q=">搜索文档</a>
          </div> -->
        </div>
      </div>
        <div class="auto-bots_container_content_body">
          <div class="message-list" v-for="(item, index) in messages" :key="index">
            <div class="message-list-me">
              <div class="message-list-me-time">
                {{ item.time }}
              </div>
              <div class="message-list-me-inner">
                <span class="inner-before"></span>
                <span class="inner-content">{{
                  item.question
                }}</span>
              </div>
            </div>
            <div class="message-list-ai" v-if="answers[index]">
              <div class="message-list-ai-time">
                <span>物流开放平台智能助手</span>{{ answers[index]["time"] }}
              </div>
              <div class="message-list-ai-inner" v-loading="answers[index]['loading']">
                <div class="message-list-ai-inner-content">
                  <p class="message-list-ai-inner-content-markdown" v-html="renderMarkdown(answers[index]['content'])"></p>
                  <!-- <p v-else>{{ answers[index]['content'] }}</p> -->
                  <!-- {{ answers[index]["content"] }} -->
                </div>
                <div class="message-list-ai-inner-action">
                  <span class="action-span-content" @click="handleLike(index,false)">
                    <img v-if="!answers[index]['isLikeRed']" class="action-span-content-img" src="@/assets/img/like.png" alt="点赞" />
                    <img v-else class="action-span-content-img" src="@/assets/img/likered.png" alt="点赞" />
                  </span>
                  <span class="action-span-content" @click="handleUnLike(index)">
                    <img v-if="!answers[index]['isUnLikeRed']" class="action-span-content-img" src="@/assets/img/unlike.png" alt="点踩" />
                    <img v-else class="action-span-content-img" src="@/assets/img/unlikered.png" alt="点踩" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <div class="auto-bots_editor__footer">
        <div class="auto-bots_container_footer">
          
          <el-form ref="ruleForm" :model="ruleForm" :rules="rules" style="height: 100%" @submit.native.prevent>
            <el-form-item class="stop-btn-container" v-if="isDisabled">
              <div class="stop-btn" @click="stopChat">
                <img class="pause-icon"
                style=""
                src="~@/assets/ToTop/pause.png"
                alt="#"
              />
                <span>停止回复</span>
              </div>
            </el-form-item>
            <el-form-item label="" prop="consultationContent" class="consultation-content" :show-message="false">
              <el-input v-model="ruleForm.consultationContent" style="height: 100%" placeholder="请输入您想查询的内容"
                :disabled="isDisabled" @keyup.enter.native="handleRequest('ruleForm', ruleForm.consultationContent)">
                <div slot="append" class="auto-bots_container_footer_button">
                  <div :class="{ disabled: isDisabled }" @click="
                    handleRequest(
                      'ruleForm',
                      ruleForm.consultationContent
                    )
                    ">
                    <img class="auto-bots_container_footer_button_img" src="@/assets/img/send.png" alt="" />
                  </div>
                </div>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <el-dialog title="您认为本条回答的问题是：" :visible.sync="dialogFormVisible" v-if="dialogFormVisible" center :append-to-body="false" width="90%"
      :modal="false" :close="handleClickClose">
      <el-form ref="feedBackForm" :model="feedBackForm" :rules="rulesFeedBackForm" >
        <el-form-item label="" prop="remarkReason">
          <el-radio-group v-model="feedBackForm.remarkReason">
            <el-radio :label="5">回答不详细</el-radio>
            <el-radio :label="6">内容有误</el-radio>
            <el-radio :label="7">回答速度慢</el-radio>
            <el-radio :label="8">其他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="">
          <el-input v-model="feedBackForm.remarkDetail" type="textarea" placeholder="请留下您的宝贵意见或建议" maxlength="150"
            show-word-limit>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

function generateChineseChars(count) {
  let result = ''
  const codeRange = [0x4e00, 0x9fa5] // 常用汉字Unicode编码范围

  for (let i = 0; i < count; i++) {
    const randomCode = Math.floor(Math.random() * (codeRange[1] - codeRange[0] + 1)) + codeRange[0]
    result += String.fromCharCode(randomCode)
  }

  return result
}
import lcp from "@/api/extranet/lcp";
import dayjs from "dayjs";
import { uniqueId} from "lodash";
const { v4: uuidv4 } = require("uuid");
import request from "@/utils/request";
import { UserModule } from "@/store/index";
import MarkdownIt from 'markdown-it';
import Axios from 'axios';
export default {
  props: {
    isZoom: Boolean,
    // chatHistory: {
    //   type: Array,
    //   default: [],
    // },
  },
  data() {
    return {
      sendTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      isUnLikeRed: false,
      isLikeRed: false,
      isDisabled: false,
      messages: [],
      answers: [],
      ruleForm: {
        consultationContent: ''
      },
      rules: {
        consultationContent: [
          {
            required: true,
            min: 0,
            max: 200,
            message: '最多输入200字',
            trigger: 'change'
          }
        ]
      },
      dialogFormVisible: false,
      feedBackForm: {
        remarkReason: '',
        remarkDetail: '',
        reqId: '',
      },
      rulesFeedBackForm: {
        remarkReason: [
          { required: true, message: '请选问题原因' }
        ]
      },
      relQuestions: [],
      introduce: '',
      traceId: uuidv4(),
      finished: false,
      bizId: uuidv4(),
      saveObj: {
        answerDetail: '', // 答案详情
        answerSource: 4, // 答案来源  1-常见问题列表  2常见问题  3-系统兜底
        bizId: '', //
        question: '', // 提问的问题
        remarkDetail: '', // 评价描述/问题描述
        remarkReason: null, //  评价原因/问题原因  1-答非所问、2-回答不详细、3-没有找到我想问的问题 4-问题列表中我问的问题不够靠前
        remarkStatus: 0, // 评价状态  0-未评价 1-点赞 2-点踩
      },
      currentIndex: 0,
      fetchCount: 0,
      md: null,
      loading: true,
      intervalId: null,
      initHistory: false,
      chatHistory: [],
    }
  },
  async mounted() {
    this.initMarkdownIt();

    // this.historyData();
    if(UserModule.name) {
      await this.queryHistoryList();
    }
    this.baseInfoRequest();

  //  this.suggestionQueryRequest();
  //  this.searchAiRequest();
  },
  methods: {
    initMarkdownIt(){
      const _this = this;
      this.md = new MarkdownIt();
      this.md.use(_this.markdownItRulesWidthHref)
      this.md.use(_this.markdownItRulesWithImg);
      
    },
    historyData() {
      const data = this.chatHistory
      console.log(data)
      data.forEach((item) => {
        this.messages.push({
          time: dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss'),
          question: item.question,
        })
        this.answers.push({
          time: dayjs(item.updateTime).format('YYYY-MM-DD HH:mm:ss'),
          content: item.answerDetail,
          type: '',
          isLikeRed: item.remarkStatus == 1,
          isUnLikeRed: item.remarkStatus == 2,
          loading: false,
          bizId: item.bizId,
        })
      })
      this.$nextTick(() => {
        this.scrollToBottom()
      })
    },
    async baseInfoRequest() {
      const result = await lcp.autobots.baseInfo({ traceId: this.traceId })
      if (result.code === 20000) {
        this.introduce = result.data.introduce
        this.relQuestions = result.data.relQuestions
      }
    },
    async suggestionQueryRequest() {
      const result = await lcp.autobots.suggestionQuery({ traceId: this.traceId, reqId: uniqueId('reqId_'), keyword: '' })
    },
    async searchAiRequest() {
      const result = lcp.autobots.searchAi({ traceId: this.traceId, reqId: uniqueId('reqId_'), keyword: '' })
    },

    async fetchData(text) {
      this.fetchCount++
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            code: 20000,
            data: {
              finished: false,
              responseType: 'markdown',
              response: ''
            }
          })
        }, 200)
      })
    },
    // async handleRequest(formName, consultationContent) {
    //     // clearInterval(intervalId);
    //     if (consultationContent.trim() === "") {
    //       return;
    //     }
    //     // this.$nextTick(() => {
    //       // this.scrollToBottom();
    //     // });
    //   // this.$refs.ruleForm.validate(async valid =>{
    //   //   if(valid){

    //       this.isDisabled = true;
    //       this.loading = true;
    //       const reqId = uuidv4();
    //       this.bizId = uuidv4();
    //       this.finished = false;
    //       const keyword = consultationContent;
    //       const currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
    //       this.messages.push({
    //         time: currentTime,
    //         question: consultationContent
    //       });
    //       this.ruleForm.consultationContent = "";

    //       this.answers.push({ time: currentTime, content: "",type:"",isLikeRed:false,isUnLikeRed:false,loading:true});
    //       this.feedBackForm.reqId = reqId;
    //       const answerIndex = this.answers.length - 1;
    //       this.currentIndex = answerIndex;
    //       this.$nextTick(() => {
    //         this.scrollToBottom();
    //       });
    //       this.intervalId = setInterval(async()=>{
    //         try {
    //          Axios.post(
    //             '/lcp-autobots/autobots/api/v1/searchAiRequest',
    //             {traceId:this.traceId,reqId,keyword,erp:"zhangjuzheng3"},
    //           {
    //             headers: { "Content-Type": "application/json","autobots-agent-id":1981,"autobots-token":"c0bff1f9a3ef456baff8d02efa969af8" },
    //           }).then(res=>{
    //             const result = res.data;
    //             // console.log(result)
    //              if(result.code == 200){
    //               this.finished = result.data.finished;
    //               let answerDetail = result.data.responseAll;
    //               if(!this.finished){
    //                 if(answerDetail){
    //                   this.answers[answerIndex]['loading'] = false;
    //                   this.$set(
    //                     this.answers[answerIndex],
    //                     "content",
    //                     answerDetail
    //                     // this.answers[answerIndex].content + answerDetail
                        
    //                   );
    //                   this.$nextTick(() => {
    //                       this.scrollToBottom();
    //                   });
    //                 }
    //               }else{
    //               console.log('result.data.responstAll---',result.data.responseAll)
    //               this.isDisabled = false;
    //               this.answers[answerIndex]['loading'] = false;
    //               this.$set(
    //                 this.answers[answerIndex],
    //                 "content",
    //                 result.data.responseAll
    //                 // +`![image.png](http://img30.360buyimg.com/document/jfs/t1/144168/8/24266/611780/622709c6E62df626a/2df554aa3b068bb1.png)`
    //               );
    //               // this.$nextTick(() => {
    //               //     this.scrollToBottom();
    //               // });
    //               // this.stopScroll();
    //               // this.handleLike(answerIndex,true)
    //               clearInterval(this.intervalId);
    //             }
                
    //             // clearInterval(intervalId);
    //           }else{
    //             this.finished = true;
    //             this.fetchCount = 0
    //             this.isDisabled = false;
    //             this.answers[answerIndex]['loading'] = false;
    //             this.$set(
    //               this.answers[answerIndex],
    //               "content",
    //               "链接超时，请重新提问"
    //             );
    //             clearInterval(this.intervalId);
    //           }
    //           }).catch(()=>{
    //             this.isDisabled = false;
    //             this.answers[answerIndex]['loading'] = false;
    //             this.$set(
    //               this.answers[answerIndex],
    //               "content",
    //               "链接超时，请重新提问"
    //             );
    //             clearInterval(this.intervalId);
    //           })
    //         } catch (error) {
    //           this.isDisabled = false;
    //           this.answers[answerIndex]['loading'] = false;
    //           this.$set(
    //             this.answers[answerIndex],
    //             "content",
    //             "链接超时，请重新提问"
    //           );
    //           console.error("Error fetching data:", error);
    //           clearInterval(this.intervalId);
    //         }
    //       },250)
    // },
    async handleRequest(formName, consultationContent) {
      if (consultationContent.trim() === '') {
        return
      }
      // this.$refs.ruleForm.validate(async valid =>{
      //   if(valid){

      this.isDisabled = true
      this.loading = true
      const reqId = uuidv4()
      this.bizId = uuidv4()
      let finished = false
      const keyword = consultationContent
      const currentTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
      this.messages.push({
        time: currentTime,
        question: consultationContent
      })
      this.ruleForm.consultationContent = ''

      this.answers.push({ time: currentTime, content: '', type: '', isLikeRed: false, isUnLikeRed: false, loading: true })
      this.feedBackForm.reqId = reqId
      const answerIndex = this.answers.length - 1;
      this.currentIndex = answerIndex;
      this.$nextTick(() => {
        this.scrollToBottom()
      })
      this.intervalId = setInterval(async() => {
        try {
          lcp.autobots.searchAi({ traceId: this.traceId, reqId, keyword }).then((res) => {
            // Axios.post(
            //   '/lcp-autobots/autobots/api/v1/searchAiRequest',
            //   {traceId:this.traceId,reqId,keyword,erp:"zhangjuzheng3"},
            // {
            //   headers: { "Content-Type": "application/json","autobots-agent-id":1981,"autobots-token":"c0bff1f9a3ef456baff8d02efa969af8" },
            // }).then(res=>{
            console.log(res)
            const result = res
            if (result.code == 20000) {
              finished = result.data.finished
              const answerDetail = result.data.responseAll
              if (!finished) {
                if (answerDetail) {
                  this.answers[answerIndex].loading = false
                  this.$set(
                    this.answers[answerIndex],
                    'content',
                    // this.answers[answerIndex].content + answerDetail
                    answerDetail
                  )
                  this.$nextTick(() => {
                    this.scrollToBottom()
                  })
                }
              } else {
                this.isDisabled = false
                this.answers[answerIndex].loading = false
                this.$set(
                  this.answers[answerIndex],
                  'content',
                  result.data.responseAll
                )
                this.handleLike(answerIndex, true)
                clearInterval(this.intervalId)
              }
            } else {
              this.isDisabled = false
              finished = true
              this.fetchCount = 0
              this.answers[answerIndex]['loading'] = false;
                this.$set(
                  this.answers[answerIndex],
                  "content",
                  "链接超时，请重新提问"
                );
              clearInterval(this.intervalId)
            }
          }).catch((err) => {
            this.isDisabled = false
            finished = true
            this.answers[answerIndex]['loading'] = false;
            this.$set(
              this.answers[answerIndex],
              "content",
              "链接超时，请重新提问"
            );
            clearInterval(this.intervalId)
          })
          // const result = await this.fetchData()
        } catch (error) {
          this.isDisabled = false;
          this.answers[answerIndex]['loading'] = false;
          this.$set(
            this.answers[answerIndex],
            "content",
            "链接超时，请重新提问"
          );
          console.error('Error fetching data:', error)
          clearInterval(this.intervalId)
        }
      }, 250)

      console.log(this.answers)
    },

    // 处理喜欢
    handleLike(index, auto) {
      if (this.answers[index].isUnLikeRed) return // 点踩后禁止点赞
      if (auto) {
        this.saveObj.remarkStatus = 0
      } else if (!this.answers[index].isLikeRed) {
        this.answers[index].isLikeRed = true
        this.saveObj.remarkStatus = 1
      } else {
        this.saveObj.remarkStatus = 0
        this.answers[index].isLikeRed = false
      }
      const answerDetail = this.answers[index].content
      const { question } = this.messages[index]
      this.saveObj.bizId = this.answers[index].bizId ? this.answers[index].bizId : this.bizId
      this.saveObj.remarkDetail = null
      this.saveObj.remarkReason = null
      this.saveObj.question = question
      this.saveObj.answerDetail = answerDetail

      this.saveRecord(this.saveObj, 'like')
      this.saveObj.remarkStatus = null
    },
    // 处理不喜欢
    handleUnLike(index) {
      // this.isUnLikeRed = !this.isUnLikeRed;
      if (this.answers[index].isUnLikeRed) return
      this.dialogFormVisible = true
      this.currentIndex = index
    },
    submitForm() {
      this.$refs.feedBackForm.validate((valid) => {
        if (valid) {
          const index = this.currentIndex
          const answerDetail = this.answers[this.currentIndex].content
          const { question } = this.messages[this.currentIndex]
          this.saveObj.bizId = this.answers[index].bizId ? this.answers[index].bizId : this.bizId
          this.saveObj.remarkStatus = 2
          this.saveObj.remarkDetail = this.feedBackForm.remarkDetail
          this.saveObj.remarkReason = this.feedBackForm.remarkReason
          this.saveObj.question = question
          this.saveObj.answerDetail = answerDetail
          this.saveObj.answerSource = 1
          this.saveRecord(this.saveObj, 'unlike')
          this.saveObj.remarkStatus = null
          this.answers[index].isUnLikeRed = !this.answers[index].isUnLikeRed
        }
      })
    },
    // 保存对话的接口
    saveRecord(saveObj, type) {
      request({
        url: '/smartrobotrecord/saveRecord',
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify({
          answerDetail: saveObj.answerDetail || null,
          answerSource: saveObj.answerSource || null,
          bizId: saveObj.bizId || null,
          question: saveObj.question || null,
          remarkDetail: saveObj.remarkDetail || null,
          remarkReason: saveObj.remarkReason || null,
          remarkStatus: saveObj.remarkStatus || 0,
          createUser: UserModule.user_pin || undefined,
        }),
      })
        .then((res) => {
          console.log(res)
          saveObj.remarkDetail = null
          saveObj.remarkReason = null
          saveObj.remarkStatus = null
          this.dialogFormVisible = false
          this.feedBackForm = {
            remarkReason: '',
            remarkDetail: ''
          }
          //
          if (type === 'unlike') {
            this.answers[this.currentIndex].isLikeRed = false
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    renderMarkdown(text) {
      return this.md.render(text)
    },
    handleClickClose() {
      this.feedBackForm = {
        remarkReason: '',
        remarkDetail: ''
      }
    },
    handleZoom() {
      this.$emit('handleZoom')
    },
    closeRobot() {
      clearInterval(this.intervalId)
      this.$emit('closeRobot')
    },
    stopChat(){
      this.answers[this.currentIndex]['loading'] = false;
      this.answers[this.currentIndex]['content'] = "您提前终止了本次回答～"
      this.isDisabled = false;
      clearInterval(this.intervalId)
    },
    scrollToBottom(){
      const container = this.$refs.scrollContainer;
      const targetScrollTop = container.scrollHeight;
      const currentScrollTop = container.scrollTop;
      container.scrollTop =  targetScrollTop
      // if (currentScrollTop < targetScrollTop) {
      //     container.scrollTop =  Math.min(container.scrollTop + 20, targetScrollTop);
      // }
    },
    markdownItRulesWithImg(){
      const defaultRender = this.md.renderer.rules.image || function(tokens, idx, options, env, self) {
        return self.renderToken(tokens, idx, options);
      };

      this.md.renderer.rules.image = function(tokens, idx, options, env, self) {
        const token = tokens[idx];
        const classIndex = token.attrIndex('class');
        if (classIndex < 0) {
          token.attrPush(['class', 'auto_bots_markdown_img']); // 添加 class 属性
        }

        // 添加 onerror 事件处理程序
        const onErrorIndex = token.attrIndex('onerror');
        if (onErrorIndex < 0) {
          token.attrPush(['onerror', `this.onerror=null;`]);
          token.attrPush(['style', `width:100%;`]);
        }else{
          token.attrPush(['style', `display:none`]); // 添加 onerror 属性
        }

        return defaultRender(tokens, idx, options, env, self);
      }
    },
    markdownItRulesWidthHref(){
      // 自定义链接渲染规则
      const defaultRender = this.md.renderer.rules.link_open || function(tokens, idx, options, env, self) {
        return self.renderToken(tokens, idx, options);
      };

      this.md.renderer.rules.link_open = function(tokens, idx, options, env, self) {
        // 如果没有 target 属性，则添加 target="_blank"
        const aIndex = tokens[idx].attrIndex('target');
        if (aIndex < 0) {
          tokens[idx].attrPush(['target', '_blank']); // 添加新的属性
        } else {
          tokens[idx].attrs[aIndex][1] = '_blank'; // 替换现有的属性值
        }

        // 继续使用默认的渲染规则
        return defaultRender(tokens, idx, options, env, self);
      };
    },
    async queryHistoryList() {
      this.initHistory = true;
      await request({
        url: "/smartrobotrecord/queryHistoryList",
        method: "get",
      })
        .then((res) => {
          this.chatHistory = res.data;
          this.historyData();
          this.initHistory = false;
        })
        .catch((error) => {
          console.log(error);
          this,this.initHistory = false;
        });
    },
  },
  beforeDestroy() {
    clearImmediate(this.intervalId);
  }
}
</script>

<style lang="scss" scoped>
.auto-bots-container {
  position: fixed;
  bottom: 10px;
  right: 19px;
  z-index: 999;
  width: 440px !important;
  height: 554px !important;

  background: #f4f5f7;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(35, 37, 43, 0.1);

  .el-dialog__wrapper {
    position: absolute;
    /* top: 140px; */
  }

}

.auto-bots-container_Zoom {
  width: 50vw !important;
  height: 100vh !important;
  bottom: 0;
  right: 0;

  .el-dialog__wrapper {
    position: absolute;
    /* top: 140px; */
  }
}

.auto-bots-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .auto-bots_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    background: linear-gradient(260.77deg,
        #eaf0ff,
        #e1eaff 72.23717%,
        #cbdbff);
    border-radius: 8px 8px 0 0;
    padding: 17px 8px 17px 16px;
    line-height: 22px;

    .header_title {
      font-size: 16px;
      line-height: 22px;
      height: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #23252b;
      margin: 0;
      padding: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      white-space: nowrap;
      cursor: pointer;

      >img {
        width: 29px;
        height: 27px;
        margin-right: 10px;
      }
    }

    .header_zoom {
      line-height: 1;
      margin-left: auto;

      .img_zoom {
        width: 16px;
        height: 16px;
      }
    }

    .header_close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      cursor: pointer;

      .im_btn_close {
        width: 19px;
        height: 19px;
      }
    }
  }

  .auto-bots_container{
    padding: 10px 15px;
  }
  .auto-bots_container_header_name {
    height: 16px;
    font-size: 12px;
    color: #868d9f;
    margin-bottom: 9px;
  }

  .auto-bots_container_header_title {
    display: flex;
    align-items: center;
    height: 54px;
    background: linear-gradient(184.12deg, #fff, #e2ebff);
    border-image: linear-gradient(175.21deg, #dae4ff, #fff) 2 2;
    border-radius: 8px;

    .auto-bots_container_header_title_img {
      width: 73px;
      height: 73px;
      margin-right: 17px;
      margin-bottom: 19px;
    }
  }
  .auto-bots_container_recommend{
    background: #fff;
    border-radius: 8px;
    color: #666;
    padding: 0 14px 0 14px;
    font-weight: 400;
    .auto-bots_container_recommend_div{
      cursor: pointer;
      height: 46px;
      padding: 12px 0;
      border-bottom: 1px solid #f1f2f4;
      }
    }
  }

.auto-bots_container_content {
  /* height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px 15px; */
  /* display: flex;
  flex-direction: column;
  height: 100%; */
  flex: 1;
  overflow-y: auto;
  padding-bottom: 60px;

  .auto-bots_container_content_body {
    /* height: 100%; */
    padding: 10px 15px;

    .message-list {
      .message-list-me {
        padding: 10px 0;
        display: flex;
        flex-direction: column;

        .message-list-me-time {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #868d9f;
          flex-direction: row;
          justify-content: flex-end;
          text-align: right;
        }

        .message-list-me-inner {
          display: flex;
          justify-content: flex-end;
          flex-direction: row;

          .inner-before {
            padding-right: 6px;
            flex: 0 0 48px;
          }

          .inner-content {
            background: rgba(60, 110, 240, 0.1);
            border-radius: 12px 0 12px 12px;
            font-size: 14px;
            font-weight: 400;
            color: #23252b;
            padding: 8px 10px;
          }
        }
      }

      .message-list-ai {
        padding: 10px 0;
        display: flex;
        flex-direction: column;

        .message-list-ai-time {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #868d9f;
          flex-direction: row;
          justify-content: flex-start;
          text-align: left;
          margin-bottom: 5px;
        }

        .message-list-ai-inner {
          min-height: 40px;
          background: #fff;
          border-radius: 8px;
          -webkit-box-shadow: 0 -2px 10px -2px rgba(0, 0, 0, 0.05);
          box-shadow: 0 -2px 10px -2px rgba(0, 0, 0, 0.05);
          font-size: 14px;
          padding: 8px 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #162024;
          .message-list-ai-inner-content{
            .message-list-ai-inner-content-markdown{
              ::v-deep a{
                  color: hsl(223, 86%, 59%) !important;
                }
              ::v-deep pre{
                white-space: normal;
                code {
                  display: block;
                  background: #303133;
                  color: #c0c4cc;
                  overflow-x: auto;
                  padding: 15px;
                  margin: 10px 0;
                  line-height: inherit;
                  word-wrap: normal;
                  word-break: normal;
                  white-space: pre;
                  font-size: 100%;
                  border-radius: 8px;
               }
              }
              // ::v-deep table{
              //   font-size: 14px;
              //   color: #606266;
              //   thead{
              //     color: #909399;
              //     font-weight: 500;
              //     tr{
              //       border: 1px solid #ddd;
              //       background: #fff;
              //       th{
              //           border: 1px solid #ddd;
              //           padding: 12px 0;
              //         }
              //       }
              //     }
              //     tbody{
              //       tr{
              //         td{
              //           border: 1px solid #ddd;
              //           padding: 12px 0;
              //         }
              //       }
              //     }
              //   }
            }
          }

          .message-list-ai-inner-action {
            display: flex;
            justify-content: flex-end;
            margin-top: 20px;
            border-top: 1px solid #f1f2f4;

            .action-span-content {
              display: flex;
              align-items: center;
              justify-content: center;
              /* float: right; */
              margin: 8px;
              margin-bottom: 0;
              cursor: pointer;
              width: 24px;
              height: 24px;
              background: #fff;
              border: 1px solid #e4e5e9;
              border-radius: 4px;

              .action-span-content-img {
                text-align: center;
                width: 14px;
                height: 14px;
              }
            }
          }
        }
      }
    }
  }
}

/* .auto-bots_editor__footer {
  height: 52px;
  background: #fafafb;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 0 8px 0 rgba(35, 37, 43, .08);
  width: 100%; */
/* position: absolute;
  bottom: 0; */
.auto-bots_container_footer {
  width: 100%;
  
  border-radius: 0 0 8px 8px;

  .el-form {
    ::v-deep .el-form-item {
      margin-bottom: 0;

      .el-form-item__content {
        height: 100%;
        .el-input__inner {
          height: 100%;
          padding: 0 12px;
          border-width: 0;
        }
        .el-input-group {
          border-radius: 0 0 8px 8px;
        }
      }

      .el-input-group__append {
        background: #fff;
        border-width: 0;
        cursor: pointer;

        .auto-bots_container_footer_button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          background: #3c6ef0;
          border-radius: 4px;
          .disabled {
            display: flex;
            width: 100%;
            height: 100%;
            color: #fff;
            pointer-events: none;
            align-items: center;
            justify-content: center;
            background: #D9D9D9;
          }
          .auto-bots_container_footer_button_img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .consultation-content{
      height: 52px;
    }
    .stop-btn-container{
      text-align: center;
      .stop-btn{
        cursor: pointer;
        width: 108px;
        color: #3c6ef0;
        border: 1px solid #ececf1;
        background: #fff;
        border-radius: 4px;
        margin: auto;
        margin-bottom: 12px;
        .pause-icon{
          width: 16px;
          height: 16px;
          vertical-align: sub;
        }
        span{
          font-size: 14px;
          margin-left: 4px;
          font-weight: 500;
        }
      }
      .stop-btn:hover{
        background: #ecf5ff;
      }
    }
  }
}

/* } */
</style>