export default {
  intranetHost: 'uat-th.lcp.jdl.com',
  gatewayOuterBaseApi: 'http://uat-intl-lop.jdl.com/',
  gatewayBaseApi: 'http://uat-intl-lop.jdl.com/',
  gatewayFileBaseApi: 'http://uat-intl-lop.jdl.com/',
  gatewayUploadLopDn: 'lop.cloud.sdkfile.com',
  gatewayUploadLopDnXml: 'xml.upload.jdl.com.uat',
  domainErp: '//ssa.jd.com',
  gatewayApiDecoupling: 'http://uat-intl-lop.jdl.com',
  jingMeAuthAlias: 'lop-intl-us-uat'
}
