// 国际站
const InternationalHostNameList = [
  'uat-th2.lcp.jdl.com', // 仅做本地启动
  'uat-th1.lcp.jdl.com', // 仅做本地启动
  'uat-hl1.lcp.jdl.com', // 仅做本地启动
  'uat-open1.cloud.jdl.com', // 仅做本地启动

  'uat-open.cloud.jdl.com', // 融合国际客户端灰度环境
  'uat-open1-cloud.jdl.com', // 融合国际客户端灰度环境
  'uat-th.lcp.jdl.com', // 内网美国站管理端域名
  'uat-hl.lcp.jdl.com', // 内网欧洲站管理端域名

  'open.cloud.jdl.com', // 融合国际客户端
  'iopen.jdl.com', // 融合国际客户端
  'th.lcp.jdl.com', // 内网美国站管理端域名
  'hl.lcp.jdl.com', // 内网欧洲站管理端域名
]
const isInternational = (): Boolean => {
  const currentHostname = window.location.hostname
  return InternationalHostNameList.includes(currentHostname)
}
export default isInternational
