
<template>
  <!-- 外网展示联系我们浮窗 -->
  <div v-show="visible" class="contact-outer-box">
    <div class="contact-outer-box-content">
      <p class="contact-title">
        {{ $t("components.OuterConnectDiloag.5iwdh1mozjk0") }}
      </p>
      <div class="contact-content">
        <div class="wechat-qrcode">
          <img class src="~@/assets/img/aboutUs/wechat-qrcode.png" alt="#" />
          <p class="qrcode-text">
            <img src="~@/assets/img/aboutUs/wechat-icon.png" alt="#" />{{
              $t("components.OuterConnectDiloag.5iwdh1mozjk1")
            }}
          </p>
          <p class="desc">周一至周五 9:00-18:00</p>
        </div>
        <!-- <div class="qq-qrcode">
          <img src="~@/assets/img/aboutUs/qq-qrcode.png" alt="#" />
          <p class="qrcode-text">
              <img src="~@/assets/img/aboutUs/qq-icon.png" alt="#" />QQ扫码
          </p>
        </div> -->
        <!-- <div class="line"></div>
        <div class="text">
          <p>
            <img
              class="advisory"
              src="~@/assets/img/aboutUs/phone-icon.png"
              alt="#"
            />{{ $t("components.OuterConnectDiloag.5iwdh1mozjk2") }}
          </p>
          <p class="contact-phone">
            <a href="tel: 13366320890">13366320890</a>
          </p>
          <p>
            <img
              class="advisory"
              src="~@/assets/img/aboutUs/email-icon.png"
              alt="#"
            />{{ $t("components.OuterConnectDiloag.5iwdh1mozjk3") }}
          </p>
          <p class="contact-email">
            <a href="mailto:jdlcloud@jd.com">jdlcloud@jd.com</a>
          </p>
          <p class="desc">
            {{ $t("components.OuterConnectDiloag.5iwdh1mp09c0") }} 9:00-18:00
          </p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OutherConnectUsDialog",
  props: ["visible"],
};
</script>
<style lang="scss" scoped>
// 外网浮窗样式
.contact-outer-box {
  position: absolute;
  height: 195px;
  width: 180px;
  right: 78px;
  top: 0;
}
.contact-outer-box-content {
  position: absolute;
  height: 195px;
  width: 180px;
  left: 0;
  top: 0;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(60, 110, 240, 0.15);
  z-index: 100;
  .contact-title {
    text-align: center;
    line-height: 22px;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    margin: 12px 0;
  }
  .contact-content {
    display: flex;
    flex-flow: row;
    .wechat-qrcode {
      margin-left: 38px;
      margin-right: 20px;
      .qrcode-text {
        text-align: center;
        font-size: 12px;
      }
      .desc {
        margin-top: 5px;
        font-size: 12px;
      }
    }
    .qq-qrcode {
      .qrcode-text {
        text-align: center;
        font-size: 12px;
      }
    }
    .line {
      width: 1px;
      height: 118px;
      background: rgba(217, 217, 217, 1);
      margin-left: 22px;
    }
    .text {
      color: #000;
      .advisory {
        width: 16px;
        height: 16px;
        margin: 2px 10px;
      }
      .contact-phone {
        color: #3c6ef0;
        margin: 6px 0px 6px 44px;
      }
      .contact-email {
        color: #3c6ef0;
        margin: 6px 0px 6px 44px;
      }
      .desc {
        font-size: 12px;
        color: #666666;
        margin-left: 20px;
        margin-top: 10px;
      }
    }
  }
}
</style>
